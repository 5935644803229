import React, { useState, useEffect } from 'react';
import WebApi from '../helpers/WebApi';
import Header from '../components/Header.jsx';
import HeaderMobile from '../components/HeaderMobile';
import Cards from '../components/cards/cards';
import './styles/all-documents.css'
import '../components/pagination/pagination.css';
import Pagination from 'rc-pagination';
import Filter from '../components/filter/filter.jsx';
import FilterSelect from '../components/filter/filterSelect.jsx';
import FilterDate from '../components/filter/filterDate.jsx';
import Session from '../helpers/Session.js';
import { useSnackbar } from '../notification/index';
import TopLoader from "react-top-loader";
import { Base64 } from 'js-base64';
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import ModalWindow from '../components/modal window/modalWindow';

function AllDocuments() {

  const session = new Session();
  const { enqueueSnackbar } = useSnackbar();
  
  let documentType =  [];
  documentType[0] = 'ОМС';
  documentType[1] = 'ДМС';
  documentType[2] = 'Коммерция';

  const [viewFormat, setViewFormat] = useState(['.bmp','.gif','.png','.jpeg','.jpg','.svg'])

  useEffect(() => {
    if (document.documentElement.clientWidth > 1024) {
      setViewFormat(['.bmp','.gif','.png','.jpeg','.jpg','.svg','.pdf']);
    } else {
      setViewFormat(['.bmp','.gif','.png','.jpeg','.jpg','.svg']);
    }
  },[])

  const [documents, setDocuments] = useState([])
  const [documentsFilter, setdocumentsFilter] = useState([]);
  const [pageCurrent,setPageCurrent] = useState(1);
  const [maxPages,setMaxPages] = useState(1);
  const [loader, setLoader] = useState(true);
  const [filterData, setFilterData] = useState({
    start_date: false,
    end_date: false,
  });

  //Управленеи фильтром на мобильном

  const [active, setActive] = useState(false);

  function ActiveOnCLick() {
    setActive(!active);
  }

  useEffect(() => {

     let web_api = new WebApi;
     let current_page = session.getSession('documents_page');
     let filter_data = session.getSession('documents_filter');

     web_api.getAllDocuments((res) => {
       
      if (res == 'error')  {
        enqueueSnackbar({
          message: 'Не удалось получить документы',
          options: {
            countdown: 5000,
            dismissible: true,
            variant: 'error'
          }
        });
        
        return false;
      }
       
      let key = 0;
      res.map((item) => {
        let date = new Date(item.date);
        item.date = 
        ('0'+(date.getDate())).slice(-2) + '.' + 
        ('0'+(date.getMonth()+1)).slice(-2) + '.' + 
        date.getFullYear();

        item.dateParse = Date.parse(date);
        item.documentType = Math.floor(Math.random() * 3);

        item.key = key; key++;
        return item;
  
      });

      setDocuments(res);
      setdocumentsFilter(res);

      setMaxPages(Math.ceil(res.length / pageSize));

      if(current_page) {
        setPageCurrent(parseInt(current_page,10));
        setStart((parseInt(current_page,10) - 1) * pageSize);
        setEnd((parseInt(current_page,10) - 1) * pageSize + pageSize);
      }

      if (filter_data) {
        setFilterData(filter_data);
      }

      setLoader(false);

    });
  },[]);

  // Узнаем длину массива для заголовка шапки
  let quantityDocuments = documents.length;

  // Пагинация страниц
  let pageSize = 12;
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(pageSize);

  function changePage(current, pageSize) {
    setPageCurrent(current);
    setStart((current - 1) * pageSize);
    setEnd((current - 1) * pageSize + pageSize);
    session.createSession('documents_page',current);
  }

  //Фильтрация

  useEffect(() => {
 
    let filterdocuments = [];
    filterdocuments = documents.filter((document) => {
      let invalid = 0;
      
      if(filterData.start_date != false && filterData.end_date != false) {
        if (document.dateParse < filterData.start_date)  invalid++; else 
          if (document.dateParse > filterData.end_date)  invalid++;
        
      }

      if (invalid == 0) return true;
    });

    setdocumentsFilter(filterdocuments);
    setMaxPages(Math.ceil(filterdocuments.length / pageSize));
    session.createSession('documents_filter',filterData);

  },[filterData]);

  function selectDate(start,end) {
    if (start,end) {
      setFilterData(prevState => ({...prevState,
        'start_date': Date.parse(start),
        'end_date': Date.parse(end)
      }));
    }
    else {
      setFilterData(prevState => ({...prevState,
        'start_date': false,
        'end_date':false
      }));
    }
    resetPage();
  }

  function resetPage() {
    setPageCurrent(1);
    setStart(0);
    setEnd(0 + pageSize);
    session.createSession('documents_page',1);
  }

  function closeSelect() {
    setFilterData(prevState => ({...prevState,
      'start_date': false,
      'end_date': false
    }));
    resetPage();
  }

  //Работа с файлами

  function convertToValidFilename(string) {
    return (string.replace(/[\/|\\:*?"<>]/g, " "));
  }

  const [image,setImage] = useState(false);

  const [ViewModalProps, setViewModalProps] = useState({});

  function viewFile(viewDocument) {
    let web_api = new WebApi;
    web_api.getPatientFileContent(viewDocument.id,(res) => {

      if (res == 'error')  {
        enqueueSnackbar({
          message: 'Не удалось открыть документ',
          options: {
            countdown: 5000,
            dismissible: true,
            variant: 'error'
          }
        });
        
        return false;
      }

      let content_type = res.headers['content-type'];
      let encode = Base64.fromUint8Array(new Uint8Array(res.data));

      if (content_type == 'application/pdf') {
        if (document.documentElement.clientWidth > 1024) {
          let props = {};
          props.title = viewDocument.name;
          props.active = true;
          props.children = generate_services(content_type, encode);
          document.querySelector('body').style.overflow = 'hidden';
          setViewModalProps(props);
        }
      } else {
        setImage({src: 'data:' + content_type + ';base64, ' + encode, title: viewDocument.name});
      }
    });
  }

  function generate_services(content_type, props) {
    return (
        <iframe src={'data:' + content_type + ';base64, ' + props} frameBorder="0" width="100%" height="100%"></iframe>
    );
  }

  function view_modal_close() {
    document.querySelector('body').style.overflow = 'auto';
    setViewModalProps(state => {state.active = false});
  }

  function closeViewFile() { setImage(false); }

  function loadFile(document) {
    let web_api = new WebApi;
    let fileDownload = require('js-file-download');

    web_api.getPatientFileStream(document.id,(res) => {

      if (res == 'error')  {
        enqueueSnackbar({
          message: 'Не удалось скачать документ',
          options: {
            countdown: 5000,
            dismissible: true,
            variant: 'error'
          }
        });
        
        return false;
      }
      
      let filename = convertToValidFilename(document.name+' '+document.date)+'.'+document.extension;
      fileDownload(res.data, filename);

    });
  }

  //Swipe
  let swipe_elemnt = document.querySelector('.swipe-animation');
  let touchStart  = 0;
  let touchEnd = 0;

  useEffect(() => {
    swipe_elemnt = document.querySelector('.swipe-animation');
  },[loader])

  function handleTouchStart(e) {
    touchStart = e.targetTouches[0].clientX;
    touchEnd = e.targetTouches[0].clientX;
  }

  function handleTouchMove(e) {
    touchEnd = e.targetTouches[0].clientX;
  }

  function handleTouchEnd(e) {
    let current = pageCurrent;

      if (touchStart - touchEnd > 75 && current < maxPages) {
          // do your stuff here for left swipe
          swipe_elemnt.style.transform = 'translateX(-64px)';
          setTimeout(() => { swipe_elemnt.style.transform = 'translateX(0px)'; },250);
          current++;

          setPageCurrent(current);
          setStart((current - 1) * pageSize);
          setEnd((current - 1) * pageSize + pageSize);
        
          session.createSession('services_page',current);
      }

      if (touchStart - touchEnd < -75  && current > 1) {
          // do your stuff here for right swipe
          swipe_elemnt.style.transform = 'translateX(64px)';
          setTimeout(() => { swipe_elemnt.style.transform = 'translateX(0px)'; },250);
          current--;

          setPageCurrent(current);
          setStart((current - 1) * pageSize);
          setEnd((current - 1) * pageSize + pageSize);
        
          session.createSession('services_page',current);

      }

  }

  return (<>
    {loader &&
      <TopLoader show color="var(--primary)" thickness="4px" />
    }
    <div className="all-documents" style={loader ? {opacity: '0.75', filter: 'blur(3px)', pointerEvents: 'none'} : {opacity: '1'}}>
      <ModalWindow {...ViewModalProps} close={view_modal_close} type="pdf-viewer" />
      {image && <Lightbox image={image.src} title={image.title} onClose={closeViewFile}/> }
      <Header title="Все документы" value={quantityDocuments}/>
      <HeaderMobile title="Все документы" value={quantityDocuments} onClick={ActiveOnCLick} active={active}/>
      <Filter active={active}>
        <FilterDate active={filterData.start_date && filterData.end_date ? true : false} start_date={filterData.start_date} end_date={filterData.end_date} onClose={closeSelect}  title="Дата предоставления" onChange={selectDate}/>
        <div className={'filter-plug'}>
          <div></div>
          <div></div>
        </div>
        <div className={'filter-plug'}>
          <div></div>
          <div></div>
        </div>
        <div className={'filter-plug'}>
          <div></div>
          <div></div>
        </div>
      </Filter>
      <Pagination
      locale="ru_RU"
      total={documentsFilter.length} 
      defaultPageSize={pageSize}
      hideOnSinglePage={true}
      showLessItems={true}
      current={pageCurrent}
      onChange={(current, pageSize) => {changePage(current, pageSize)}}
      />
      {!loader && <>
        {documentsFilter.length ? (<>
        <div className="all-documents__container swipe-animation" onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
        
          {documentsFilter.slice(start,end).map(document => {
              return <div className="all-documents__block" key={document.id}>
                <Cards 
                title={document.name ? document.name : '---'}
                type="card-documents" 
                subtitle={document.date ? document.date : '---'}
                price = {document.price}
                status={document.paymentStatus}
                onClickLeft={viewFormat.indexOf(document.extension) != -1 ? (() => {viewFile(document);}) : (undefined)}
                onClickRight={(event) => {loadFile(document);}}
                >
                  {document.doctorUserName &&
                    <p>Врач: {document.doctorUserName}</p>
                  }
                  {document.clinicBranchName &&
                    <p>Филиал: {document.clinicBranchName}</p>
                  }
                </Cards>
              </div>
            })}
        </div>
        </>) : (<>
          По данному фильтру документов не найдено
        </>)}
      </>}
      <div className="end_page" style={maxPages > 1 ? {paddingBottom:'66px'} : {}}>
        ---
      </div>
    </div>
    </>)

}

export default AllDocuments;