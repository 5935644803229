import React from 'react';
import '../reception/reception-block.css';
import Done from '../../img/done.png'


function ReceptionSuccess({active, data}) {
  let config = window.config;

  return (
    <div className={"reception-success"}>
      <div className="reception-success__container">
        <div className="reception-success__title">
          <div className="reception-success__img">
            <img src={Done}/>
          </div>
          <div className='reception-success__title-text'>Вы успешно записаны на прием</div>
        </div>
        <div className='reception-success__info-container'>
          <div className="reception-success__info">
            <div className="reception-success__time">
              {data.dateText} на {data.timeBegin != false ? (data.timeBegin.slice(0,-3) + " - " + data.timeEnd.slice(0,-3)) : "33"}
              <span>к врачу {data.doctorText}</span>
            </div>
            <div><span>Филиал: </span>{data.name}</div>
            {data.adress != '' && data.adress != null && data.adress != undefined &&
              <div><span>Адрес: </span>{data.adress}</div>
            }
            <div><span>Услуга: </span>{data.serviceText}</div>
            {config.show_services_price == true &&
              <div><span>Цена услуги: </span>{data.price}</div>
            }
            <div><span>Врач: </span>{data.doctorText}</div>
            <div><span>Тип услуги: </span>{data.typeServiceText}</div>
          </div>
          {config['map_link'] && data.isOneBranch
            ? <div className='reception-success__info-map'>
              <iframe src={config['map_link']}  width='100%' height='100%' frameBorder="0"></iframe>
            </div>
            : ''
          } 
        </div>
      </div>
    </div>
  )

}

export default ReceptionSuccess;