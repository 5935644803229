import React, { useEffect, useState } from 'react';

function FilterSelect({options,onClick,id,title,active,value}) {

    const [selectActive, setSelectActive] = useState(active);
    const [nameSelect, setNameSelect] = useState('Нажмите для фильтрации');

    useEffect(() => {
        document.addEventListener('click', outSideClick, true);
        return () => {
        document.removeEventListener('click', outSideClick, true);
        };
        
    },[]);

    function outSideClick(event) {
        let stop_outside = event.srcElement.classList.contains('stop_outside');
        if (!stop_outside) {
            let select_options = document.querySelector('.filter-select_options.active');
            if (select_options) {
                setSelectActive(false);
            }
        }
    }

    function openSelect() {
        setSelectActive(!selectActive);
    } 

    function selectClick(event) {
        let name = event.target.innerText;
        setNameSelect(name);
        onClick(event);
    }

    function CloseSelect() {
        setSelectActive(false);
        let name = 'Нажмите для фильтрации';
        setNameSelect(name);
        onClick();
    }

    
    useEffect(() => {
        if (active) setNameSelect(options[value]);
    },[active]);

    return (<>
        {options ? (
            <div id={id} className={"filter-select" + `${selectActive == true ? " active" : ""}`}>
                <div className="filter-select__container">
                    <div className="filter-select__content" onClick={openSelect}>
                        <div className={"filter-select__title"+`${nameSelect != 'Нажмите для фильтрации' ? " bold" : ""}`}>
                            {title}
                        </div>
                        <div className={"filter-select_option active"+`${nameSelect == 'Нажмите для фильтрации' ? " disabled" : ""}`} >{nameSelect}</div>
                        <div className={"filter-select_options" + `${selectActive == true ? " active" : ""}`}>
                            {options.map((name,i) =>
                                <div key={i} className="filter-select_option stop_outside" id={i} onClick={selectClick} >{name}</div>  
                            )} 
                        </div>
                    </div>
                    <div className={"filter-select__button-close" + `${nameSelect != 'Нажмите для фильтрации' ? " active" : ""}`} onClick={CloseSelect}>
                        <span className="icon icon_xs icon_background-auto icon_close-filter"></span>
                    </div>
                </div>
            </div>
        ):(
            <div className={'filter-plug'}>
                <div></div>
                <div></div>
            </div>
        )}
    </>)

}

export default FilterSelect;