import React, { useState, useEffect } from 'react';
import WebApi from '../helpers/WebApi';
import Session from '../helpers/Session.js';
import { useSnackbar } from '../notification/index';
import { extendMoment } from "moment-range";
import Header from '../components/Header.jsx';
import HeaderMobile from '../components/HeaderMobile.jsx';
import ReceptionBlock from '../components/reception/reception-block.jsx';
import ReceptionBranch from '../components/reception/ReceptionBranch.jsx';
import ReceptionServices from '../components/reception/ReceptionServices.jsx';
import ReceptionDoctor from '../components/reception/ReceptionDoctor.jsx';
import ReceptionSuccess from '../components/reception/ReceptionSuccess.jsx';
import ReceptionFinish from '../components/reception/ReceptionFinish.jsx';
import TopLoader from "react-top-loader";
import originalMoment from "moment";
import 'moment/locale/ru';
import '../components/reception/reception-block.css';

const moment = extendMoment(originalMoment);

function Reception() {

  let web_api = new WebApi;
  let config = window.config;

  const { enqueueSnackbar } = useSnackbar();

  // Стилизация загрузки страницы до ответа с сервера
  const [loader, setLoader] = useState(true);
  const [receptionLoader, setReceptionLoader] = useState(false);

  // Переменная для сбора данных для отправки на сервер
  const [data, setData] = useState({});

  // Переменная для отображения текущего этапа записи на приём
  const [currentTab, setCurrentTab] = useState('branch');

  // Переменная с типами услуг 
  const [typeService, setTypeService] = useState([]);

  // Активный тип услуги
  const [activeType, setActiveType] = useState(false);

  // Переменные для передачи данных в каждый этап
  const [branches, setBranches] = useState([]); // филиалы с сервера
  const [servicesReception, setServicesReception] = useState([]); // Услуги с сервера
  const [doctorReception, setDoctorReception] = useState([]); // Список доступынх врачей

  // id выбранного филиала для сраванения с типом услуги в Reception services
  const [idType, setIdType] = useState('');

  const [messageActive, setMessageActive] = useState(false);

  // Переключение табов на пк версии
  function selectTab(tab) {

    if (tab.currentTarget.id == "branch") {
      setServicesReception([]);
      setActiveType(false);
    }

    if (typeof tab === "string") {
      setCurrentTab(tab);
    } else {
      setCurrentTab(tab.currentTarget.id);
    }

  }

  // Переключение табов на модбльной версии

  // переменная с названиями блоков
  const allTabs = ['branch', 'services', 'doctor', 'success', 'finish'];

  if (branches.length == 1) {
    allTabs.splice(0, 1);
  }

  // Функция переключения на шаг вперед
  function NextClick() {
    let key = allTabs.indexOf(currentTab);
    setCurrentTab(allTabs[key + 1]);
  }

  // Функция переключения на шаг назад
  function PrevClick() {
    if (currentTab == "services") {
      setServicesReception([]);
      setActiveType(false);
    }
    let key = allTabs.indexOf(currentTab);
    setCurrentTab(allTabs[key - 1]);
  }


  // Запрос для получения доступных типов услуг
  useEffect(() => {

    web_api.getAppointmentTypes((res) => {

      if (res == 'error') {
        enqueueSnackbar({
          message: 'Не удалось получить типы услуг',
          options: {
            countdown: 5000,
            dismissible: true,
            variant: 'error'
          }
        });
      } else {

        if (res.length == 1) {
          let params = data;
          params.typeServiceText = res[0].value;
        }

        setTypeService(res);
      }

    });

  }, []);

  // РАБОТА С ФИЛИАЛАМИ

  // Переменная, куда заносятся данные, если филиал всего 1. 
  // При изменении данной константы отрабатывает Use Effect с запросом к API
  const [oneBranch, setOneBranch] = useState(data)

  useEffect(() => {
    // Запрос на получение услуги по id филиалу и типу услуги
    web_api.getOnlineServices(oneBranch, function (res) {

      if (res == 'error') {
        enqueueSnackbar({
          message: 'Не удалось получить услуги',
          options: {
            countdown: 5000,
            dismissible: true,
            variant: 'error'
          }
        });

        return false;

      } else {

        // Автовыбор услуги если она одна
        if (res.length == 1) {
          oneBranch.serviceId = res[0].id;
          oneBranch.fromDate = start_period.start_date.format('YYYY-MM-DD');
          oneBranch.tillDate = start_period.end_date.format('YYYY-MM-DD');
          oneBranch.serviceText = res[0].name;
          oneBranch.typeServiceText = typeService[0].value;

          getDoctorsFreeTime(oneBranch);
          setData(oneBranch);
        } else {
          setReceptionLoader(false);
          setData(oneBranch);
          setServicesReception(res);
          setActiveType(true);
        }

      }

    });
  }, [oneBranch]);


  // Запрос доступных филиалов с сервера
  useEffect(() => {

    web_api.getClinicBranches((res) => {

      if (res == 'error') {
        enqueueSnackbar({
          message: 'Не удалось получить филиалы',
          options: {
            countdown: 5000,
            dismissible: true,
            variant: 'error'
          }
        });
        return false
      } else {
        setLoader(false);

        // Убираем выбор филиала, если филиал 1
        if (res.length == 1) {

          let params = data;

          params.clinicBranchId = res[0].id;
          params.serviceType = 0;
          params.name = res[0].name;
          params.adress = res[0].addressLocation;
          // params.typeServiceText = typeService[0].value;

          // Добавляем id филиала
          setIdType(params.serviceType);

          // Добаляем в переменную данные единственного филиала
          setOneBranch({...params,  'isOneBranch': true});  // Дальше запрос в API производится в отдельном UseEffect

          setBranches(res);
          setCurrentTab('services');

        } else {
          setBranches(res);
        }
      };
    });
  }, []);

  // Функция выбора филиала
  function SelectBranch(event) {

    let params = data;
    let branch_id;

    if (!event.currentTarget.id) branch_id = event.target.id; else branch_id = event.currentTarget.id;

    params.clinicBranchId = branch_id;
    params.serviceType = typeService[0].key;

    // Вставляем данные для блока success и finish
    params.name = event.currentTarget.dataset.name;
    params.adress = event.currentTarget.dataset.adress;
    params.typeServiceText = typeService[0].value;

    // Добавляем id филиала
    setIdType(event.target.id);

    // Запрос на получение услуги по id филиалу и типу услуги
    web_api.getOnlineServices(params, function (res) {

      if (res == 'error') {
        enqueueSnackbar({
          message: 'Не удалось получить услуги',
          options: {
            countdown: 5000,
            dismissible: true,
            variant: 'error'
          }
        });

        return false;

      } else {

        // Автовыбор услуги если она одна
        if (res.length == 1) {

          params.serviceId = res[0].id;
          params.fromDate = start_period.start_date.format('YYYY-MM-DD');
          params.tillDate = start_period.end_date.format('YYYY-MM-DD');
          params.serviceText = res[0].name;

          getDoctorsFreeTime(params);
        }

        setReceptionLoader(false);
        setData(params);
        setServicesReception(res);
        setActiveType(true);
        setCurrentTab('services')
      }

    });

    let session = new Session;

    if (session.getSession('MessageSwipe')) {
      setMessageActive(false);
    }
    else {
      setMessageActive(true);
      session.createSession('MessageSwipe', true, 1000 * 60 * 60 * 24 * 30);
    }

  }

  // РАБОТА С УСЛУГАМИ

  // Функция выбора типа услуги
  function SelectType(event) {

    let params = data;

    params.serviceType = parseInt(event.target.dataset['id']);
    params.typeServiceText = event.target.innerText;

    // Добавляем id филиала
    setIdType(params.serviceType);

    // Запрос на получение услуги по id филиалу и типу услуги
    web_api.getOnlineServices(params, function (res) {

      if (res == 'error') {
        enqueueSnackbar({
          message: 'Не удалось получить услуги',
          options: {
            countdown: 5000,
            dismissible: true,
            variant: 'error'
          }
        });

        return false;

      } else {

        // Автовыбор услуги если она одна
        if (res.length == 1) {

          params.serviceId = res[0].id;
          params.fromDate = start_period.start_date.format('YYYY-MM-DD');
          params.tillDate = start_period.end_date.format('YYYY-MM-DD');
          params.serviceText = res[0].name;

          getDoctorsFreeTime(params);
        }

        setReceptionLoader(false);
        setData(params);
        setServicesReception(res);
        setActiveType(true);
      }

    });

  }

  // Переменная с текущей неделей
  let [start_period, setStartPeriod] = useState({
    start_date: moment().startOf("isoWeek"),
    end_date: moment().endOf("isoWeek")
  });

  // Функция выбора услуги
  function SelectService(event) {

    let params = data;
    let serviceSelect;

    if(event.target.className == 'reception-services__block'){
      serviceSelect = (event.target.firstElementChild.innerText).replace(/[0-9]/g, '');
    } else {
      serviceSelect = (event.target.innerText).replace(/[0-9]/g, '');
    }

    if(config.show_services_price && event.target.lastChild.innerText || event.target.nextSibling.innerText){
      params.price = event.target.lastChild.innerText || event.target.nextSibling.innerText;
    }

    params.serviceId = event.target.id || event.target.firstElementChild.id;
    params.fromDate = start_period.start_date.format('YYYY-MM-DD');
    params.tillDate = start_period.end_date.format('YYYY-MM-DD');
    params.serviceText = serviceSelect.replace(/\./g, "");

    // Вызов функции запроса свободных врачей
    getDoctorsFreeTime(params);
  }


  // РАБОТА С ДОКТОРАМИ

  // Функция запроса свободных врачей по id услуги
  function getDoctorsFreeTime(params) {

    setReceptionLoader(true);

    web_api.getDoctorsFreeTime(params, function (res) {
      setReceptionLoader(false);

      if (res == 'error') {
        enqueueSnackbar({
          message: 'Не удалось получить врачей',
          options: {
            countdown: 5000,
            dismissible: true,
            variant: 'error'
          }
        });

        return false;

      } else {

        // Добавление к запросу массива свободного времени врачей
        let new_res = res.map(doctor => {

          doctor.arrFreeTimes = {};
          doctor.freeWindowDay = {};

          doctor.freeTimes.map(time => {
            if (!doctor.arrFreeTimes[time.date]) doctor.arrFreeTimes[time.date] = [];

            doctor.arrFreeTimes[time.date].push(time);

            if (doctor.freeWindowDay[time.date] == undefined) doctor.freeWindowDay[time.date] = 0;

            if (time.isFreeDocTime) {
              let counter = doctor.freeWindowDay[time.date];
              doctor.freeWindowDay[time.date] = counter + 1;
            }
          });
          return doctor;
        });

        setDoctorReception(new_res);
        setCurrentTab('doctor');

      }
    });
  }

  // Функция закрытия окна уведомления о свайпах на мобилке
  function closeMessageSwipe() {
    setMessageActive(false);
  }

  // Функции переключения месяц и недель на блоке с докторами

  // След. неделя
  function clickNextWeek(period) {

    let params = data;

    params.fromDate = period.start_date.format('YYYY-MM-DD');
    params.tillDate = period.end_date.format('YYYY-MM-DD');

    setStartPeriod(period);
    getDoctorsFreeTime(params);
  }

  // Пред. неделя
  function clickPrevWeek(period) {

    let params = data;

    params.fromDate = period.start_date.format('YYYY-MM-DD');
    params.tillDate = period.end_date.format('YYYY-MM-DD');

    setStartPeriod(period);
    getDoctorsFreeTime(params);
  }

  // След. месяц
  function clickNextMounth(period) {

    let params = data;

    params.fromDate = period.start_date.format('YYYY-MM-DD');
    params.tillDate = period.end_date.format('YYYY-MM-DD');

    setStartPeriod(period);
    getDoctorsFreeTime(params);
  }

  // След. месяц
  function clickPrevMounth(period) {

    let params = data;

    params.fromDate = period.start_date.format('YYYY-MM-DD');
    params.tillDate = period.end_date.format('YYYY-MM-DD');

    setStartPeriod(period);
    getDoctorsFreeTime(params);
  }

  // Функция выбора доктора
  function SelectDoctor(doctor, time, setDoctorsModalProps) {

    let date = new Date(time.date);
    date =
      ('0' + (date.getDate())).slice(-2) + '.' +
      ('0' + (date.getMonth() + 1)).slice(-2) + '.' +
      date.getFullYear();

    setData(prevState => ({
      ...prevState,
      'doctorSpecializationId': doctor.id,
      "timeBegin": time.timeBegin,
      "timeEnd": time.timeEnd,
      "cabinetId": time.cabinetId,
      'date': time.date,
      'dateText': date,
      "doctorText": doctor.userName,
    }));

    document.querySelector('body').style.overflow = '';
    setDoctorsModalProps(state => { state.active = false });
    setCurrentTab('success');
  }


  // РАБОТА С ОТПРАВКОЙ ДАННЫХ

  // Функция проверки данных комментария (Если написан текст, то отправляется в data)
  function getComment(event) {
    setData(prevState => ({
      ...prevState,
      'comment': event.target.value
    }));
  }

  // Функция провреки и отправки данных на сервер
  function Success(event) {

    setReceptionLoader(true);

    web_api.sendPostSchedule(data, function (res) {
      setReceptionLoader(false);

      if (res == 'error') {
        enqueueSnackbar({
          message: 'Не удалось завершить запись',
          options: {
            countdown: 5000,
            dismissible: true,
            variant: 'error'
          }
        });
        return false;
      } else {
        setCurrentTab('finish');
      }
    });
  }



  return (<>
    {loader &&
      <TopLoader show color="var(--primary)" thickness="4px" />
    }
    <div className="reception" style={loader ? { opacity: '0.75', filter: 'blur(3px)', pointerEvents: 'none' } : { opacity: '1' }}>
      <Header title="Запись на прием" type="reception" />
      <HeaderMobile title="Запись на приём" type="reception" />
      <ReceptionBlock data={data} currentTab={currentTab} NextClick={NextClick} PrevClick={PrevClick} activeDone={currentTab == 'finish' ? true : false} branchesCount={branches.length} onClick={(event) => { selectTab(event) }}>
        {!loader && <div style={receptionLoader ? { opacity: '0.75', filter: 'blur(3px)', pointerEvents: 'none', transition: 'all 0.5s ease' } : { opacity: '1', transition: 'all 0.5s ease' }}>
          {currentTab == 'branch' &&
            <ReceptionBranch data={branches} onClick={event => { SelectBranch(event) }} />
          }
          {currentTab == 'services' &&
            <ReceptionServices
              data={servicesReception}
              typeService={typeService}
              onClick={event => { SelectService(event) }}
              onClickType={event => SelectType(event)}
              activeType={activeType}
              idType={idType}
            />
          }
          {currentTab == 'doctor' &&
            <ReceptionDoctor
              data={doctorReception}
              start_period={start_period}
              clickNextWeek={clickNextWeek}
              clickPrevWeek={clickPrevWeek}
              clickNextMounth={clickNextMounth}
              clickPrevMounth={clickPrevMounth}
              onClick={SelectDoctor}
              closeMessageSwipe={closeMessageSwipe}
              messageActive={messageActive}
            />
          }
          {currentTab == 'success' &&
            <ReceptionSuccess data={data} onClick={event => { Success(event) }} onChange={event => { getComment(event) }} />
          }
          {currentTab == 'finish' &&
            <ReceptionFinish data={data} />
          }
        </div>}
      </ReceptionBlock>
    </div>
  </>)

}

export default Reception;