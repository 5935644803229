import React, { useContext } from 'react';
import { useLocation } from 'react-router';
import Buttons from './buttons/buttons';
import './styles/header.css'
import Menu from './menu/menu';
import Context from '../context.js'
import Profile from './Profile';

function Header({ title, value,type }) {

  if (!title) title = "";

  const [global, setGlobal] = useContext(Context);

  function profile() { 
    setGlobal(prevState => ({...prevState,
      profile: {
        data: prevState.profile.data,
        toggle: true
      }
    }));
  }

  // Перенаправление на 1 этап записи на прием, при нажатии на кнопку "Записаться на приём"

  let location = useLocation();

  function ReceptionReload() {
    if (location.pathname == '/reception') {
      window.location.reload();
    }
  }

  return (
        <div className="header">
          <div className="header__container">
          { !type && 
            <div className="header__title">
              <h1>{(title) + " ("+ (value)+")"}</h1>
            </div>
          }
          {type && 
            <div className="header__title">
              <h1>{title}</h1>
            </div>
          }
            <div className="header__content">
              <div className="header__button-record">
                <Buttons to="/reception" type="button-primary" icon="icon icon_s icon_button-record icon_background-auto" text="Записаться на прием" onClick={ReceptionReload}/>
              </div>
              <div className="header__profile-block">
                <div className="header__profile-text">
                  <div className="header__profile-name">
                    {global.profile.data && <>
                      {global.profile.data.lastName && <>{global.profile.data.lastName} </> }
                      {global.profile.data.firstName && <>{global.profile.data.firstName[0]}. </> }
                      {global.profile.data.midName && <>{global.profile.data.midName[0]}. </> }
                    </>}
                  </div>
                  <div className="header__profile-link" onClick={profile}>
                    Мой профиль
                  </div>
                </div>
                <div className="header__profile-photo">
                  <Menu/>
                </div>
              </div>
            </div>
          </div>
        </div>
  )

}

export default Header;