import React, { useEffect, useState } from 'react';

function FilterRadio({value,onClick,id, title, active}) {

    const [radioActive, setRadioActive] = useState(active);

    function radioClick(event) {
        onClick(value);
    }

    function closeRadio() {
        onClick();
    }

    useEffect(() => {
        setRadioActive(active);
    },[active]);

    return (
        <div id={id} className="filter-select">
            <div className="filter-select__container">
                <div className="filter-select__content" onClick={radioClick}>
                    <div className={"filter-select__title"+`${radioActive ? " bold" : ""}`}>
                        {title}
                    </div>
                    <div className={"filter-select_option active"+`${!radioActive ? " disabled" : ""}`} >
                        {radioActive ? ( 
                            <span>Активно</span>
                        ) : (
                            <span>Нажмите для фильтрации</span>
                        )}
                    </div>
                    <div className={"filter-select_options" + `${radioActive == true ? " active" : ""}`}>
                    </div>
                </div>
                <div className={"filter-select__button-close" + `${radioActive ? " active" : ""}`} onClick={closeRadio}>
                    <span className="icon icon_xs icon_background-auto icon_close-filter"></span>
                </div>
            </div>
        </div>
    )

}

export default FilterRadio;