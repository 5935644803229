import React, {useState} from 'react';
import Buttons from '../buttons/buttons';
import './modalWindow.css';

function ModalWindow( {title, before_title, after_title, active, close, children, type}) {
  
    let width = window.screen.width;
    let IconClose = 'icon_xl';
    let IconCalendar = 'icon_xl';

    const [widthScreen, setWithScreen] = useState(width);

    window.onresize = function() {
        setWithScreen(width);
    };

    if (widthScreen <= 768) {
        IconClose = 'icon_m';
        IconCalendar = 'icon_l';
    } else {
        IconClose = 'icon_xl';
        IconCalendar = 'icon_xl';
    }

  return (
    <>
    {active && type == "modal-window" &&
        <div id="test" ref={this} className={"modal-window"}>
            <div className="modal-window__close"><Buttons type="button-close" icon={"icon "+ (IconClose) + " icon_button-close icon_background-auto"} onClick={close}/></div>
            <div className="modal-window__scroll">
                <div className="modal-window__container">
                    <div className="modal-window__content">
                        <div className="modal-window__header">
                            <div className="modal-window__subtitle">
                                {before_title}
                            </div>
                            <div className="modal-window__title">
                                {title}
                            </div>
                            {after_title &&
                                <div className="modal-window__time">
                                    <div className="modal-window__time-icon">
                                        <span className={"icon "+ (IconCalendar) + " icon_background-auto icon_calendar"}></span>
                                    </div>
                                    <div className="modal-window__time-text">
                                        {after_title}
                                    </div>
                                </div>
                            }
                        </div>
                        <div className={"modal-window__main"+ `${title == "Отмена записи" ? " cansel" : ""}`}>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
    {active && type == "pdf-viewer" &&
        <div ref={this} className={"modal-window modal-window_pdf"}>
            <div className="modal-window__scroll">
                <div className="modal-window__container">
                    <div className="modal-window__header">
                        <div className="modal-window__header-title" title={title}>
                            <span>{title}</span>
                        </div>
                        <div className="modal-window__close">
                            <div onClick={close}></div>
                        </div>
                    </div>
                    <div className="modal-window__content">
                        <div className={"modal-window__main"+ `${title == "Отмена записи" ? " cansel" : ""}`}>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
    </>
  )

}

export default ModalWindow;
