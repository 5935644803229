import React, { useContext, useEffect, useState } from 'react';
import WebApi from '../helpers/WebApi.js';
import Session from '../helpers/Session';
import './styles/profile.css';
import Buttons from '../components/buttons/buttons.jsx';
import DiscountBlock from './discountBlock';
import Context from '../context.js'
import { useSnackbar } from '../notification/index';
import ModalWindow from './modal window/modalWindow.jsx';
import Inputs from '../components/forms/inputs'


function Profile({ toggle }) {
 
    const { enqueueSnackbar } = useSnackbar();
    
    const [profile, setProfile] = useState([]);

    const [global, setGlobal] = useContext(Context);

    const [ModalPropsPassword, setModalPropsPassword] = useState({});
   

    useEffect(() => {
        if (!profile.length) {
            let web_api = new WebApi;
            web_api.getPatientInfo((res) => {

                if (res == 'error')  {
                    enqueueSnackbar({
                    message: 'Не удалось получить данные профиля',
                    options: {
                        countdown: 50000,
                        dismissible: true,
                        variant: 'error'
                    }
                    });
                    
                    return false;
                }
                
                let date = new Date(res.birthDay);
                res.birthDay = 
                ('0'+(date.getDate())).slice(-2) + '.' + 
                ('0'+(date.getMonth()+1)).slice(-2) + '.' + 
                date.getFullYear();
                res.phone = [res.phone.slice(0,1), " (", res.phone.slice(1,4), ") ", res.phone.slice(4,7), "-", res.phone.slice(7,9), "-", res.phone.slice(9,11)].join('');
                setProfile(res);
                setGlobal(prevState => ({...prevState,
                    profile: {
                        data: res,
                        toggle: prevState.profile.toggle
                    }
                }));
            });
        }
    },[]);

    function close() {
        setGlobal(prevState => ({...prevState,
            profile: {
              data: prevState.profile.data,
              toggle: false
            }
          }));
    }

     // Функция закрытия модального окна 
     function ModalClosePassword() {
        document.querySelector('body').style.overflow = 'auto';
        setModalPropsPassword(state => {state.active = false});
    }

    const [errorPass, setErrorPass] = useState({
        old: false,
        new: false,
        old_message: '',
        new_message: ''
    });

    // Функция отправки запроса на смену пароля
    function sendPass(event) {
        event.preventDefault();
    
        let data = {
          "oldPassword": event.target.querySelector('[name="old_password"]').value,
          "newPassword": event.target.querySelector('[name="new_password"]').value,
        };

        let mask = /^([0-9a-zA-Z\._-]{8,})*$/;

        if (!data.newPassword.match(mask) || data.newPassword.length == 0) {
            setErrorPass(prevState => ({...prevState,
                "new": true,
                "new_message": "Пароль должен содержать больше 8 знаков и состоять из букв латинского алфавита A-z, цифр 0-9 и знаков - _ ."
            }));
            
        } else {

            setErrorPass(prevState => ({...prevState,
                "new": false,
                "new_message": ""
            }));
            
            let web_api = new WebApi;
            web_api.postChangePassword(data,ChangePassword);

        }

    }

    function ChangePassword(response) {

        if (response.status == 200){

            let session = new Session;
            session.createSession('token',response.data);
            ModalClosePassword();
            enqueueSnackbar({
                message: 'Пароль успешно изменен',
                options: {
                countdown: 5000,
                dismissible: true,
                variant: 'success'
                }
            });

        } else {
            
            enqueueSnackbar({
                message: response.data,
                options: {
                countdown: 5000,
                dismissible: true,
                variant: 'error'
                }
            });

        }
        
    }

    // Передача данных в модальное окно
    function ModalPassword() {
        let props = {};
        props.before_title = 'Для смены пароля, заполните форму';
        props.title = 'Изменить пароль';
        props.active = true;
        document.querySelector('body').style.overflow = 'hidden';
        setModalPropsPassword(props);
    }

    return (
        <div>
            <ModalWindow {...ModalPropsPassword} close={ModalClosePassword} type="modal-window">
                <div className="modal-password">
                    <form onSubmit={(event) => sendPass(event)}>
                        <div className={"modal-password__old-pass"}>
                            <Inputs 
                                type="password"
                                name="old_password"
                                placeholder="Старый пароль"
                                className="input"
                                iconSize="icon_s"
                                iconImage="icon_input-password"
                                error={errorPass.old}
                                errorMessage={errorPass.old_message}
                            />
                        </div>
                        <div className="modal-password__new-pass">
                            <Inputs 
                                type="password"
                                name="new_password"
                                placeholder="Новый пароль"
                                className="input"
                                iconSize="icon_s"
                                iconImage="icon_input-password"
                                error={errorPass.new}
                                errorMessage={errorPass.new_message}
                            />
                        </div>
                        <div className="modal-password__form-button">        
                            <Buttons text="Изменить пароль" type="button-primary" icon="icon icon_s icon_change-password icon_background-auto icon_right"/>
                        </div>
                    </form>
                </div>
            </ModalWindow>
            <div className={"profile-plug" + `${global.profile.toggle ? " active" : ""}`}></div>
            <div className={"profile"+ `${global.profile.toggle ? " active" : ""}`}>
                <div className="profile__container">
                    <div className="profile__header">
                        <div className="profile__mobile-header">
                            <div className="profile__mobile-title">
                                Мой профиль
                            </div>
                            <div className="profile__button-mobile-close">
                                <Buttons type="button-close" icon={"icon icon_s icon_button-close icon_background-auto"} onClick={close}/>
                            </div>
                        </div>
                        <div className="profile__button-close">
                            <Buttons type="button-close" icon={"icon icon_s icon_button-close icon_background-auto"} onClick={close}/>
                        </div>
                        <div className="profile__header-text">
                            <div className="profile__header-welcome">
                                Добро пожаловать в свой профиль
                            </div>
                            <div className="profile__header-name">
                                {profile.lastName && <>{profile.lastName}</>} 
                                {profile.firstName && <>{" " + (profile.firstName)}</>} 
                                {profile.midName && <>{" " + (profile.midName)}</>} 
                            </div>
                        </div>
                    </div>
                    {profile.discount != null && profile.discount != 0 &&
                        <div className="profile__discount">
                            <DiscountBlock discount={profile.discount}/>
                        </div>
                    }
                    <div className="profile__content">
                        <div className="profile__content-item">
                            <strong>Вы родились: </strong>{profile.birthDay && <>{profile.birthDay}</>} 
                        </div>
                        <div className="profile__content-item">
                        <strong>Номер вашего телефона: </strong>{profile.phone && <>{profile.phone}</>}
                        </div>
                        <div className="profile__content-item">
                        <strong>Почта для обратной связи: </strong>{profile.email && <>{profile.email}</>}
                        </div>
                        <div className="profile__content-item">
                        <strong>Пароль: </strong><Buttons style={{'display':'inline-block'}} type="link" icon="icon icon_s icon_change-password icon_background-auto icon_left" text="сменить пароль" onClick={ModalPassword}/>
                        </div>
                    </div>
                    <div className="profile__footer">
                        (!) В случае нахождения ошибок в регистрационных данных, пожалуйста обратитесь в клинику.
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Profile;
