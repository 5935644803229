import React, { useEffect, useState, Component } from 'react';
import './styles/services.css'
import WebApi from '../helpers/WebApi';
import Header from '../components/Header.jsx';
import HeaderMobile from '../components/HeaderMobile';
import Cards from '../components/cards/cards';
import '../components/pagination/pagination.css';
import Pagination from 'rc-pagination';
import ModalWindow from '../components/modal window/modalWindow';
import Filter from '../components/filter/filter.jsx';
import FilterSelect from '../components/filter/filterSelect.jsx';
import FilterDate from '../components/filter/filterDate.jsx';
import Session from '../helpers/Session.js';
import { useSnackbar } from '../notification/index';
import TopLoader from "react-top-loader";

function Services() {

  let config = window.config;
  const session = new Session();
  const { enqueueSnackbar } = useSnackbar();

  let pageSize = 12;
  let currency = config.currency;

  const [typeServices, setTypeServices] = useState(false);

  let statusPay = [];
  statusPay[0] = 'Не оплачен';
  statusPay[1] = 'Оплачен';
  statusPay[2] = 'Есть задолженность';

  const [services, setServices] = useState([]);
  const [servicesFilter, setServicesFilter] = useState([]);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [maxPages, setMaxPages] = useState(1);
  const [loader, setLoader] = useState(true);
  const [filterData, setFilterData] = useState({
    type: false,
    status: false,
    start_date: false,
    end_date: false,
  });

  //Управленеи фильтром на мобильном

  const [active, setActive] = useState(false);

  function ActiveOnCLick() {
    setActive(!active);
  }

  //Получение данных из API

  useEffect(() => {

    let web_api = new WebApi;
    let current_page = session.getSession('services_page');
    let filter_data = session.getSession('services_filter');

    web_api.getAppointmentTypes((res) => {

      if (res == 'error') {
        enqueueSnackbar({
          message: 'Не удалось получить типы услуг',
          options: {
            countdown: 5000,
            dismissible: true,
            variant: 'error'
          }
        });

        return false;
      }

      let tpyes = [];
      res.map((type) => { tpyes[type.key] = type.value; });
      setTypeServices(tpyes);

    });

    web_api.getServicesUser((res) => {

      console.log(res)

      if (res == 'error') {
        enqueueSnackbar({
          message: 'Не удалось получить услуги',
          options: {
            countdown: 50000,
            dismissible: true,
            variant: 'error'
          }
        });

        return false;
      }


      let key = 0;
      res.map((item) => {
        let date = new Date(item.date);

        item.date =
          ('0' + (date.getDate())).slice(-2) + '.' +
          ('0' + (date.getMonth() + 1)).slice(-2) + '.' +
          date.getFullYear();

        item.dateParse = Date.parse(date);

        item.key = key; key++;
        return item;

      });

      setServices(res);
      setServicesFilter(res);

      setMaxPages(Math.ceil(res.length / pageSize));

      if (current_page) {
        setPageCurrent(parseInt(current_page, 10));
        setStart((parseInt(current_page, 10) - 1) * pageSize);
        setEnd((parseInt(current_page, 10) - 1) * pageSize + pageSize);
      }

      if (filter_data) {
        setFilterData(filter_data);
      }

      setLoader(false);

    });

  }, []);

  // Узнаем длину массива для заголовка шапки
  let quantityServices = services.length;

  // Пагинация страниц
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(pageSize);

  function changePage(current, pageSize) {
    setPageCurrent(current);
    setStart((current - 1) * pageSize);
    setEnd((current - 1) * pageSize + pageSize);
    session.createSession('services_page', current);
  }

  //Фильтрация

  useEffect(() => {

    let filterServices = [];

    filterServices = services.filter((service) => {
      let invalid = 0;

      if (filterData.type) if (service.serviceType != filterData.type) invalid++;
      if (filterData.status) if (service.paymentStatus != filterData.status) invalid++;

      if (filterData.start_date != false && filterData.end_date != false) {
        if (service.dateParse < filterData.start_date) invalid++; else
          if (service.dateParse > filterData.end_date) invalid++;

      }

      if (invalid == 0) return true;
    });

    setServicesFilter(filterServices);
    setMaxPages(Math.ceil(filterServices.length / pageSize));
    session.createSession('services_filter', filterData);

  }, [filterData]);

  function selectTypeServices(event) {
    if (event) {
      setFilterData(prevState => ({
        ...prevState,
        'type': event.target.id
      }));
    }
    else {
      setFilterData(prevState => ({
        ...prevState,
        'type': false
      }));
    }
    resetPage();
  }

  function selectstatusPay(event) {
    if (event) {
      setFilterData(prevState => ({
        ...prevState,
        'status': event.target.id
      }));
    }
    else {
      setFilterData(prevState => ({
        ...prevState,
        'status': false
      }));
    }
    resetPage();
  }

  function selectDate(start, end) {
    if (start, end) {
      setFilterData(prevState => ({
        ...prevState,
        'start_date': Date.parse(start),
        'end_date': Date.parse(end)
      }));
    } else {
      setFilterData(prevState => ({
        ...prevState,
        'start_date': false,
        'end_date': false
      }));
    }
    resetPage();
  }

  function resetPage() {
    setPageCurrent(1);
    setStart(0);
    setEnd(0 + pageSize);
    session.createSession('services_page', 1);
  }

  function closeSelect() {
    setFilterData(prevState => ({
      ...prevState,
      'start_date': false,
      'end_date': false
    }));
    resetPage();
  }

  //Swipe
  let swipe_elemnt = document.querySelector('.swipe-animation');
  let touchStart = 0;
  let touchEnd = 0;

  useEffect(() => {
    swipe_elemnt = document.querySelector('.swipe-animation');
  }, [loader])

  function handleTouchStart(e) {
    touchStart = e.targetTouches[0].clientX;
    touchEnd = e.targetTouches[0].clientX;
  }

  function handleTouchMove(e) {
    touchEnd = e.targetTouches[0].clientX;
  }

  function handleTouchEnd(e) {
    let current = pageCurrent;

    if (touchStart - touchEnd > 75 && current < maxPages) {
      // do your stuff here for left swipe
      swipe_elemnt.style.transform = 'translateX(-64px)';
      setTimeout(() => { swipe_elemnt.style.transform = 'translateX(0px)'; }, 250);
      current++;

      setPageCurrent(current);
      setStart((current - 1) * pageSize);
      setEnd((current - 1) * pageSize + pageSize);

      session.createSession('services_page', current);
    }

    if (touchStart - touchEnd < -75 && current > 1) {
      // do your stuff here for right swipe
      swipe_elemnt.style.transform = 'translateX(64px)';
      setTimeout(() => { swipe_elemnt.style.transform = 'translateX(0px)'; }, 250);
      current--;

      setPageCurrent(current);
      setStart((current - 1) * pageSize);
      setEnd((current - 1) * pageSize + pageSize);

      session.createSession('services_page', current);

    }

  }

  return (<>
    {loader &&
      <TopLoader show color="var(--primary)" thickness="4px" />
    }
    <div className="services" style={loader ? { opacity: '0.75', filter: 'blur(3px)', pointerEvents: 'none' } : { opacity: '1' }}>
      <ModalWindow />
      <Header title="Оказанные услуги" value={quantityServices} />
      <HeaderMobile title="Оказанные услуги" value={quantityServices} onClick={ActiveOnCLick} active={active} />
      <Filter active={active}>
        <FilterDate active={filterData.start_date && filterData.end_date ? true : false} start_date={filterData.start_date} end_date={filterData.end_date} title="Дата оказания" onClose={closeSelect} onChange={selectDate} />
        <FilterSelect active={filterData.type ? true : false} value={filterData.type} title="Вид услуги" options={typeServices} onClick={(event) => selectTypeServices(event)}></FilterSelect>
        <FilterSelect active={filterData.status ? true : false} value={filterData.status} title="Статус оплаты" options={statusPay} onClick={(event) => selectstatusPay(event)}></FilterSelect>
        <div className={'filter-plug'}>
          <div></div>
          <div></div>
        </div>
      </Filter>
      <Pagination
        locale="ru_RU"
        total={servicesFilter.length}
        defaultPageSize={pageSize}
        hideOnSinglePage={true}
        showLessItems={true}
        current={pageCurrent}
        onChange={(current, pageSize) => { changePage(current, pageSize) }}
      />
      {!loader && <>
        {servicesFilter.length ? (<>
          <div className="services__container swipe-animation" onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
            {servicesFilter.slice(start, end).map(service => {
              return <div className="services__block" key={service.key}>
                <Cards
                  title={service.serviceName ? service.serviceName : '---'}
                  type="card-services"
                  subtitle={service.date ? service.date : '---'}
                  price={service.price ? (parseFloat(service.price).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1 ").replace('.', ',') + " " + (currency)) : '---'}
                  status={service.paymentStatus}
                >
                  <p>Доктор: {service.doctorName ? service.doctorName : '---'}</p>
                  <p>Специализация: {service.specializationName ? service.specializationName : '---'}</p>
                  {service.totalDiscount > 0 &&
                    <p>Скидка: {service.totalDiscount} {currency}</p>
                  }
                  <p>Вид услуги: {typeServices[service.serviceType] ? typeServices[service.serviceType] : '---'}</p>
                  {service.clinicBranchName &&
                    <p>Филиал: {service.clinicBranchName}</p>
                  }
                </Cards>
              </div>
            })}
          </div>
        </>) : (<>
          По данному фильтру услуг не найдено
        </>)}
      </>}
      <div className="end_page" style={maxPages > 1 ? { paddingBottom: '66px' } : {}}>
        ---
      </div>
    </div>
  </>)

}

export default Services;
