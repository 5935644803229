import React, { useEffect, useState } from 'react';
import WebApi from '../helpers/WebApi';
import Header from '../components/Header.jsx';
import HeaderMobile from '../components/HeaderMobile';
import Cards from '../components/cards/cards.jsx';
import './styles/mynotes.css';
import '../components/pagination/pagination.css';
import Pagination from 'rc-pagination';
import ModalWindow from '../components/modal window/modalWindow';
import Filter from '../components/filter/filter.jsx';
import FilterRadio from '../components/filter/filterRadio.jsx';
import FilterDate from '../components/filter/filterDate.jsx';
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import 'moment/locale/ru';
import Session from '../helpers/Session.js';
import { useSnackbar } from '../notification/index';
import TopLoader from "react-top-loader";
import Buttons from '../components/buttons/buttons.jsx';
const moment = extendMoment(originalMoment);


function MyNotes() {


  let config = window.config;
  const session = new Session();
  const { enqueueSnackbar } = useSnackbar();
  let currency = config.currency;
  const [reload, setReload] = useState(false);

  const [typeServices, setTypeServices] = useState(false);

  // текущая дата
  let current_date = new Date() //'2021-07-06T09:44:00'
  let current_dateParse = Date.parse(current_date);

  const [servicesNotes, setServicesNotes] = useState([]);
  const [NotesFilter, setNotesFilter] = useState([]);
  const [radioName, setRadioName] = useState(false);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [maxPages, setMaxPages] = useState(1);
  const [loader, setLoader] = useState(true);
  const [filterData, setFilterData] = useState({
    start_date: false,
    end_date: false,
    name: false
  });

  //Управленеи фильтром на мобильном

  const [active, setActive] = useState(false);

  function ActiveOnCLick() {
    setActive(!active);
  }

  function count_days(date) {
    let days = 0;
    let dateParse = Date.parse(date);

    if (current_dateParse < dateParse) {
      if (current_date)
        days = (dateParse - current_dateParse) / (86400000);

      if (date.getFullYear() != current_date.getFullYear()) {
        days = Math.ceil(days);
      } else {
        if (date.getMonth() != current_date.getMonth()) {
          days = Math.ceil(days);
        } else {
          if (date.getDate() != current_date.getDate()) {
            days = Math.ceil(days);
          }
        }
      }

    }
    else
      days = -1;

    return days;
  }

  useEffect(() => {

    let web_api = new WebApi;
    let current_page = session.getSession('my_notes_page');
    let filter_data = session.getSession('my_notes_filter');

    web_api.getAppointmentTypes((res) => {

      if (res == 'error') {
        enqueueSnackbar({
          message: 'Не удалось получить типы услуг',
          options: {
            countdown: 5000,
            dismissible: true,
            variant: 'error'
          }
        });

        return false;
      }

      let tpyes = [];
      res.map((type) => { tpyes[type.key] = type.value; });
      setTypeServices(tpyes);

    });

    // Функция сортировки запесей по дате

    function NotesSort(notesArray) {
      notesArray.sort(function (a, b) {

        if (a.isCompleted != true && b.isCompleted != true) {
          a = a.DateFormat;
          b = b.DateFormat;

          return a < b ? -1 : a > b ? 1 : 0;
        }
      })
    }

    web_api.getSchedules((res) => {

      if (res == 'error') {
        enqueueSnackbar({
          message: 'Не удалось получить записи',
          options: {
            countdown: 5000,
            dismissible: true,
            variant: 'error'
          }
        });

        return false;
      }

      let key = 0;
      res.map((item) => {

        let date = new Date(item.date);

        // Дата в формате new Date с часами начала приёма для правильной сортировки по дате
        let hoursTimeBegin = item.timeBegin.slice(0, -6);
        let minutesTimeBegin = item.timeBegin.slice(3, -3);
        item.DateFormat = new Date(date.setHours(hoursTimeBegin, minutesTimeBegin, 0, 0));

        // Добавляем к дате приёма часы окончания приёма для высчета дней, оставшихся до приёма
        let dateReception = new Date(date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + (date.getDate())).slice(-2) + 'T' + item.timeEnd);

        item.dateFull = date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate();

        item.date =
          ('0' + (date.getDate())).slice(-2) + '.' +
          ('0' + (date.getMonth() + 1)).slice(-2) + '.' +
          date.getFullYear();

        item.timeBegin = item.timeBegin.slice(0, -3);

        item.dateParse = Date.parse(date);

        item.days = count_days(dateReception);

        item.key = key; key++;
        return item;

      });

      // Вызываем функцию сортировки по дате
      NotesSort(res);

      setServicesNotes(res);
      setNotesFilter(res);

      setMaxPages(Math.ceil(res.length / pageSize));

      if (current_page) {
        setPageCurrent(parseInt(current_page, 10));
        setStart((parseInt(current_page, 10) - 1) * pageSize);
        setEnd((parseInt(current_page, 10) - 1) * pageSize + pageSize);
      }

      if (filter_data) {
        setFilterData(filter_data);
        setRadioName(filter_data.name);
      }

      setLoader(false);

    });

  }, [reload]);


  // Узнаем длину массива для заголовка шапки
  let quantityNotes = servicesNotes.length;

  // Пагинация страниц
  let pageSize = 12;
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(pageSize);

  function changePage(current, pageSize) {
    setPageCurrent(current);
    setStart((current - 1) * pageSize);
    setEnd((current - 1) * pageSize + pageSize);
    session.createSession('my_notes_page', current);
  }

  //Модальное окно услуги

  const [servicesModalProps, setServicesModalProps] = useState({});

  function generate_services(services) {
    return (services.length ? (<>
      <div className="modal-window__serives" style={(services.length < 3) ? { justifyContent: "center" } : { justifyContent: "flex-start" }}>
        {services.map((service, i) =>
          <div className="modal-window__serives-block" key={i}>
            <Cards
              title={service.serviceName}
              type="card-services"
              subtitle={"Дата оказания: " + (service.date)}
              price={parseFloat(service.price).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1 ").replace('.', ',') + " " + (currency)}
              key={service}
            >
              {service.doctorName != null && (<p>Доктор: {service.doctorName}</p>)}
              {service.specializationName != null && (<p>Специализация: {service.specializationName}</p>)}
              {service.discount != null && (<p>Скидка: {service.discount} % </p>)}
              {service.serviceType != null && (<p>Вид услуги: {typeServices[service.serviceType]}</p>)}
              {service.clinicBranchName != null && (<p>Филиал: {service.clinicBranchName}</p>)}
            </Cards>
          </div>)}
      </div>
    </>) : (<>
      <div style={{ textAlign: 'center', fontSize: '24px' }}>Нет услуг по данной записи</div>
    </>)
    );
  }

  function ServiceModal(note) {
    let scheduleId = note.id;

    let web_api = new WebApi;
    web_api.getSchedulesServices(scheduleId, SchedulesServices);

    function SchedulesServices(res) {

      if (res == 'error') {
        enqueueSnackbar({
          message: 'Не удалось получить записи',
          options: {
            countdown: 5000,
            dismissible: true,
            variant: 'error'
          }
        });
        return false;
      }

      res.map((item) => {
        let date = new Date(item.date);

        item.date =
          ('0' + (date.getDate())).slice(-2) + '.' +
          ('0' + (date.getMonth() + 1)).slice(-2) + '.' +
          date.getFullYear();

        return item;
      });

      let props = {};
      props.before_title = '"' + note.specializationName + '" ' + note.doctorName;
      props.title = 'Список услуг';
      props.after_title = note.date;
      props.active = true;
      props.children = generate_services(res);
      document.querySelector('body').style.overflow = 'hidden';
      setServicesModalProps(props);

    }


  }

  function service_modal_close() {
    document.querySelector('body').style.overflow = 'auto';
    setServicesModalProps(state => { state.active = false });
  }


  //Фильтрация

  let onDay = {
    'start_date': moment().startOf('day').toDate(),
    'end_date': moment().endOf('day').toDate(),
    'name': 'onDay'
  };

  let onWeek = {
    'start_date': moment().startOf('week').toDate(),
    'end_date': moment().endOf('week').toDate(),
    'name': 'onWeek'
  };

  let onMonth = {
    'start_date': moment().startOf('month').toDate(),
    'end_date': moment().endOf('month').toDate(),
    'name': 'onMonth'
  };

  function radioDate(value) {
    if (value) {
      setFilterData(prevState => ({
        ...prevState,
        'start_date': Date.parse(value.start_date),
        'end_date': Date.parse(value.end_date),
        'name': value.name
      }));
      setRadioName(value.name);
    }
    else {
      setFilterData(prevState => ({
        ...prevState,
        'start_date': false,
        'end_date': false,
        'name': false
      }));
      setRadioName(false);
    }
    resetPage();
  }

  function selectDate(start, end) {
    if (start, end) {
      setFilterData(prevState => ({
        ...prevState,
        'start_date': Date.parse(start),
        'end_date': Date.parse(end),
        'name': 'period'
      }));
      setRadioName('period');
    }
    else {
      setFilterData(prevState => ({
        ...prevState,
        'start_date': false,
        'end_date': false,
        'name': false
      }));
      setRadioName(false);
    }

    resetPage();
  }

  useEffect(() => {


    let filterNotes = [];

    filterNotes = servicesNotes.filter((note) => {
      let invalid = 0;

      if (filterData.start_date != false && filterData.end_date != false) {
        if (note.dateParse < filterData.start_date) invalid++; else
          if (note.dateParse > filterData.end_date) invalid++;
      }

      if (invalid == 0) return true;
    });

    setNotesFilter(filterNotes);
    setMaxPages(Math.ceil(filterNotes.length / pageSize));
    session.createSession('my_notes_filter', filterData);

  }, [filterData]);;


  function resetPage() {
    setPageCurrent(1);
    setStart(0);
    setEnd(0 + pageSize);
    session.createSession('my_notes_page', 1);
  }

  function closeSelect() {
    setFilterData(prevState => ({
      ...prevState,
      'start_date': false,
      'end_date': false,
      'name': false
    }));
    resetPage();
  }


  // Отмена записи

  function generate_input(note) {
    return (
      <div className="modal-window__input-block">
        <form id={note.id} onSubmit={event => CancelHandler(event)}>
          <div className="modal-window__textarea">
            <textarea name="comment" placeholder="Укажите причину отмены записи (необязательно)" />
          </div>
          <div className="modal-window__button">
            <Buttons type="button-primary" typeButton="submit" text="Отменить данную запись" icon="icon icon_s icon_background-auto icon_reception-done" />
          </div>
        </form>
      </div>
    );
  }

  function CancelRecord(note) {
    let props = {};
    props.before_title = '"' + note.specializationName + '" ' + note.doctorName;
    props.title = 'Отмена записи';
    props.after_title = note.date;
    props.active = true;
    props.children = generate_input(note);
    document.querySelector('body').style.overflow = 'hidden';
    setServicesModalProps(props);
  }

  function CancelHandler(event) {
    event.preventDefault();
    let data = {
      id: event.target.id,
      comment: event.target.querySelector('textarea').value
    };

    let web_api = new WebApi;

    web_api.getCanselSchedule(data, CanselSchedule);

    function CanselSchedule(res) {

      if (res == 'error') {
        enqueueSnackbar({
          message: 'Не удалось отменить запись',
          options: {
            countdown: 5000,
            dismissible: true,
            variant: 'error'
          }
        });
        return false;
      }

      enqueueSnackbar({
        message: 'Запись успешно отменена',
        options: {
          countdown: 5000,
          dismissible: true,
          variant: 'info'
        }
      });

      setReload(!reload);
      document.querySelector('body').style.overflow = 'auto';
      setServicesModalProps(state => { state.active = false });

    }

    return false;
  }
  //Swipe
  let swipe_elemnt = document.querySelector('.swipe-animation');
  let touchStart = 0;
  let touchEnd = 0;

  useEffect(() => {
    swipe_elemnt = document.querySelector('.swipe-animation');
  }, [loader])

  function handleTouchStart(e) {
    touchStart = e.targetTouches[0].clientX;
    touchEnd = e.targetTouches[0].clientX;
  }

  function handleTouchMove(e) {
    touchEnd = e.targetTouches[0].clientX;
  }

  function handleTouchEnd(e) {
    let current = pageCurrent;

    if (touchStart - touchEnd > 75 && current < maxPages) {
      // do your stuff here for left swipe
      swipe_elemnt.style.transform = 'translateX(-64px)';
      setTimeout(() => { swipe_elemnt.style.transform = 'translateX(0px)'; }, 250);
      current++;
      setPageCurrent(current);
      setStart((current - 1) * pageSize);
      setEnd((current - 1) * pageSize + pageSize);

      session.createSession('services_page', current);
    }

    if (touchStart - touchEnd < -75 && current > 1) {
      // do your stuff here for right swipe
      swipe_elemnt.style.transform = 'translateX(64px)';
      setTimeout(() => { swipe_elemnt.style.transform = 'translateX(0px)'; }, 250);
      current--;

      setPageCurrent(current);
      setStart((current - 1) * pageSize);
      setEnd((current - 1) * pageSize + pageSize);

      session.createSession('services_page', current);

    }

  }

  return (<>
    {loader &&
      <TopLoader show color="var(--primary)" thickness="4px" />
    }
    <div className="my-notes" style={loader ? { opacity: '0.75', filter: 'blur(3px)', pointerEvents: 'none' } : { opacity: '1' }}>
      <ModalWindow type="modal-window" {...servicesModalProps} close={service_modal_close} />
      <Header title="Мои приемы" value={quantityNotes} />
      <HeaderMobile title="Мои записи" value={quantityNotes} onClick={ActiveOnCLick} active={active} />
      <Filter active={active}>
        <FilterRadio active={radioName == "onDay" ? true : false} title="Показать на сегодня" value={onDay} onClick={(value) => radioDate(value)}></FilterRadio>
        <FilterRadio active={radioName == "onWeek" ? true : false} title="Показать за неделю" value={onWeek} onClick={(value) => radioDate(value)}></FilterRadio>
        <FilterRadio active={radioName == "onMonth" ? true : false} title="Показать за месяц" value={onMonth} onClick={(value) => radioDate(value)}></FilterRadio>
        <FilterDate active={radioName == "period" ? true : false} start_date={filterData.start_date} end_date={filterData.end_date} onClose={closeSelect} title="Показать за период" onChange={selectDate} />
      </Filter>
      <Pagination
        locale="ru_RU"
        total={NotesFilter.length}
        defaultPageSize={pageSize}
        hideOnSinglePage={true}
        showLessItems={true}
        current={pageCurrent}
        onChange={(current, pageSize) => { changePage(current, pageSize) }}
      />
      {!loader && <>
        {NotesFilter.length ? (<>
          <div className="my-notes__container swipe-animation" onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
            {NotesFilter.slice(start, end).map(servicesNote => {
              return <div className="all-documents__block" key={servicesNote.id}>
                <Cards
                  title={servicesNote.doctorName ? servicesNote.doctorName : '---'}
                  type="card-notes"
                  info={servicesNote.date + " " + servicesNote.timeBegin}
                  subtitle={servicesNote.specializationName ? servicesNote.specializationName : '---'}
                  days={servicesNote.days && servicesNote.days}
                  ModalWindow={() => ServiceModal(servicesNote)}
                  onClickRight={() => CancelRecord(servicesNote)}
                  isCompleted={servicesNote.isCompleted && servicesNote.isCompleted}
                  isCanceled={servicesNote.isCanceled && servicesNote.isCanceled}

                >
                  {servicesNote.doctorUserName &&
                    <p>Врач: {document.doctorUserName}</p>
                  }
                  {servicesNote.clinicBranchName &&
                    <p>Филиал: {servicesNote.clinicBranchName}</p>
                  }
                </Cards>
              </div>
            })}
          </div>
        </>) : (<>
          По данному фильтру записей не найдено
        </>)}
      </>}
      <div className="end_page" style={maxPages > 1 ? { paddingBottom: '66px' } : {}}>
        ---
      </div>
    </div>
  </>)


}

export default MyNotes;