import './styles/login.css';
import '../components/forms/forms.css';
import { NavLink } from 'react-router-dom';



function ResetPassword() {

  let config = window.config;

  return (
    <div className="login login_reset-password">
        <div className="login__container">
          <div className="login__logo">
            <img src={config.logo} alt="" />
          </div>
          <div className="login__title">
            Забыли пароль?
          </div>
          <div className="login__subtitle">
            Для восстановления пароля<br/>обратитесь в клинику по номеру телефона:
          </div>
          <div className="login__number">
            {config.phone_reset_pass}
          </div>
          <div className="login__input">
            <NavLink to="/">Войти</NavLink>
          </div>
        </div>
    </div>
  )
}

export default ResetPassword;