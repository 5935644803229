import React, { useState } from 'react';
import '../reception/reception-block.css';
import Buttons from '../buttons/buttons';


function ReceptionSuccess({ active, onClick, data, onChange }) {

  let config = window.config;

  return (
    <div className={"reception-date"}>
      <div className="reception-date__container">
        <div className="reception-date__title">Запись на приём</div>
        <div className="reception-date__time">{data.dateText} на {data.timeBegin != false ? (data.timeBegin.slice(0, -3) + " - " + data.timeEnd.slice(0, -3)) : "33"}</div>
        <div className="reception-date__info">
          <div><span>Филиал: </span>{data.name}</div>
          {data.adress != '' && data.adress != null && data.adress != undefined &&
            <div><span>Адрес: </span>{data.adress}</div>
          }
          <div><span>Услуга: </span>{data.serviceText}</div>
          {config.show_services_price == true &&
            <div><span>Цена услуги: </span>{data.price}</div>
          }
          <div><span>Врач: </span>{data.doctorText}</div>
          <div><span>Тип услуги: </span>{data.typeServiceText}</div>
        </div>
        <div className="reception-date__info-policy">Нажимая кнопку <b>"Подтвердить запись"</b> я даю свое согласие на обработку моих персональных данных, определенных в <a href={config.link_policy} target="_bank">"Согласии на обработку персональных данных"</a>. </div>
        <div className="reception-date__comment">
          <textarea name="comment" id="" placeholder="Комментарий (необязательно)" onChange={onChange}></textarea>
        </div>
        <div className="reception-date__button-done">
          <Buttons type="button-primary" text="Подтвердить запись" icon="icon icon_s icon_background-auto icon_reception-done" onClick={onClick} />
        </div>
      </div>
    </div>
  )

}

export default ReceptionSuccess;