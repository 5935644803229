import React, {useEffect, useState} from 'react';
import '../reception/reception-block.css';
import moment from "moment";
import Period from '../../helpers/Period.js';
import "../filter/react-calendar.css";
import { useSnackbar } from '../../notification/index';
import ModalWindow from '../../components/modal window/modalWindow';
import Buttons from '../buttons/buttons';
import ReceptionDoctorMobile from './ReceptionDoctorMobile';
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import Search from '../search/Search';
import MiniModal from '../mini modal/miniModal';



function ReceptionDoctor({active, onClick, start_period, data, clickNextWeek, clickPrevWeek, clickNextMounth, clickPrevMounth, messageActive, closeMessageSwipe}) {

  const { enqueueSnackbar } = useSnackbar();

  // console.log(data);

  const [filtered,setFiltered] = useState(data);

  let width = window.screen.width;
  const [widthScreen, setWithScreen] = useState(width);
  window.onresize = function() {
    setWithScreen(width);
  };

  // Обновление данных в поиске при обновлении данных data
  useEffect(() => {
    setFiltered(data);
  },[data]);
  
  function searchResult(filter_array) {

    if (filter_array || filter_array.length) {
      setFiltered(filter_array);
    } else {
      setFiltered(data);
    }
  
  }
  let get_period = new Period;

  const [period, setPeriod] = useState(start_period);

  var days = [];

  for (let i = 0; i <= 6; i++) {
      let moment_date = moment(period.start_date).add(i, 'days');
      let name = moment_date.format('dddd');
      let day = {
        name:  name[0].toUpperCase() + name.slice(1),
        date: moment_date.format("DD.MM.YYYY"),
        date_utc: moment_date.startOf('day').format('YYYY-MM-DDTHH:mm:ss')
      };
      days.push(day);

  };

  function nextWeek() {
    let newPeriod = get_period.get_nextWeek(period);
    setPeriod(newPeriod);
    clickNextWeek(newPeriod);
  }

  function prevWeek() {
    let newPeriod = get_period.get_prevWeek(period);
    setPeriod(newPeriod);
    clickPrevWeek(newPeriod);
  }

  function nextMounth() {
    let newPeriod = get_period.get_nextMonth(period);
    setPeriod(newPeriod);
    clickNextMounth(newPeriod);
  }

  function prevMounth() {
    let newPeriod = get_period.get_prevMounth(period);
    setPeriod(newPeriod);
    clickPrevMounth(newPeriod);
  }

  // Модальное окно 

  const [DoctorsModalProps, setDoctorsModalProps] = useState({});

  function DoctorModal(doctor,day) {
      let props = {};
      props.before_title = doctor.userName;
      props.title = 'Запись на приём';
      props.after_title = day.date + ' (' + day.name + ')';
      props.active = true;
      props.children = generate_reception(doctor,day);
      document.querySelector('body').style.overflow = 'hidden';
      setDoctorsModalProps(props);

  }

  function generate_reception(doctor,day) {
    let times = doctor.arrFreeTimes[day.date_utc];
    return (
      <div className="reception__time">
        {times.map((time,i) => 
          <div key={i} className="reception__time-block" onClick={() => { if (time.isFreeDocTime) onClick(doctor,time,setDoctorsModalProps)}}>
            {time.isFreeDocTime ? (
              <Buttons type="button-time-stamp" text={(time.timeBegin.slice(0,-3))+" - "+(time.timeEnd.slice(0,-3))}/>
            ) : (
              <Buttons disabled={true} type="button-time-stamp" text={(time.timeBegin.slice(0,-3))+" - "+(time.timeEnd.slice(0,-3))}/>
            )}
          </div> 
        )}
      </div>
    )
  }

  function service_modal_close() {
    document.querySelector('body').style.overflow = 'auto';
    setDoctorsModalProps(state => {state.active = false});
  }

  //Фото врачей
  
  const [image,setImage] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  
  const [isModal, setModal] = useState(false);

  function viewDoctorInfo(index){   
    let specializations = filtered[index].specializations.map((e, i) => {
      return ' ' + e.name + (filtered[index].specializations.length > 1 && i != Number(filtered[index].specializations.length - 1) ? ',' : '');
    })
    setSelectedDoctor({...filtered[index], specializations, fullName: filtered[index].fullName || filtered[index].userName});
    setModal(true);
  }

  function viewPhoto(doctor) {
    setImage({src: doctor.photoUrl, title: doctor.userName});
  }
  
  function closeViewFile() { setImage(false); }
  
  return (
    <>
    {image && <Lightbox image={image.src} title={image.title} onClose={closeViewFile}/> }
    <ModalWindow {...DoctorsModalProps} close={service_modal_close} type="modal-window" />
    <MiniModal
      isVisible={isModal}
      title={selectedDoctor?.fullName}
      content={<p><b>{selectedDoctor?.specializations}</b> <br/>{selectedDoctor?.description} </p>}
      imageSrc={selectedDoctor?.photoUrl}
      onClose={(e) => {e.stopPropagation();setModal(false)}}
    />
    {active && 
      <Search data={data} field="userName" callback={searchResult}/>
    }
    {widthScreen > 1024 ? (
      <div className={"reception-doctor"}>
        <div className="reception-doctor__container">
          <div className="reception-doctor__period" style={{marginTop:"-16px"}}>
            <div className="reception-doctor__week">
              <div className="reception-doctor__prevweek" onClick={prevWeek} >
                <div><span className="icon icon_xs icon_background-auto icon_prev"></span></div>
                <div>Предыдущая неделя</div>
              </div>
              <div className="reception-doctor__nextweek" onClick={nextWeek}>
                <div>Следующая неделя</div>
                <div><span className="icon icon_xs icon_background-auto icon_next"></span></div>
              </div>
            </div>
            <div className="reception-doctor__mounth">
              <div className="reception-doctor__prevmounth" onClick={prevMounth}>
                <div><span className="icon icon_xs icon_background-auto icon_prev"></span></div>
                <div>Предыдущий месяц</div>
              </div>
              <div className="reception-doctor__nextmounth" onClick={nextMounth}>
                <div>Следующий месяц</div>
                <div><span className="icon icon_xs icon_background-auto icon_next"></span></div>
              </div>
            </div>
          </div>
          <div className="reception-doctor__header">
            <div className="reception-doctor__fixed-block">
              <div className="reception-doctor__fixed-text">
                <div>Фото</div>
              </div>
              <div className="reception-doctor__fixed-text">
                <div>Специалист</div>
                <div>Ф.И.О</div>
              </div>
            </div>
            {days.map((day,i) =>
            <div className="reception-doctor__header-block" key={i}>
              <div>{day.name}</div>
              <div>{day.date}</div>
            </div>
            )}
          </div>
          <div className="reception-doctor__content">
            {filtered.length ? <>{filtered.map((doctor,index) => 
              <div className="reception-doctor__content-block" key={index}>
                <div className="reception-doctor__doctor">
                  
                  <div className="reception-doctor__photo-doctor">
                    {doctor.photoUrl != null ?
                      (<img src={doctor.photoUrl} onClick={(e) => {e.stopPropagation(); viewDoctorInfo(index)}} />) :
                      (<span className="icon icon_xxl icon_background-auto icon_profile-block" onClick={(e) => {e.stopPropagation(); viewDoctorInfo(index)}}></span>)
                    }
                  </div>
                  <div>
                    {doctor.userName}
                    <div className='doctor__about' onClick={(e) => {e.stopPropagation(); viewDoctorInfo(index)}}><u>О враче</u></div>
                  </div>
                </div>
                {days.map((day,i) => {
                return (<React.Fragment key={i}>
                  {doctor.arrFreeTimes[day.date_utc] != undefined ? (<>
                    <div className={"reception-doctor__time" + `${doctor.freeWindowDay[day.date_utc] ? " active" : ""}`} onClick={() => { if (doctor.freeWindowDay[day.date_utc]) DoctorModal(doctor,day); }}>
                      <span>c {doctor.arrFreeTimes[day.date_utc][0].startWorkTime.slice(-8,-3)} до {doctor.arrFreeTimes[day.date_utc][0].endWorkTime.slice(-8,-3)}</span>
                      <span>выбрать время</span>
                    </div>
                  </>) : 
                  (<div className="reception-doctor__time">----</div>)
                  }
                  </React.Fragment>);
                  
                })}
              </div>
            )}</> : 
              <div className="reception-doctor__content-block">
                {filtered || Array.isArray(filtered) ? (<>
                  По данному запросу врачи не найдены
                </>) : (<>
                  Нет записей на данный период
                </>)}
              

              </div>
            }
          </div>
        </div>
    </div>
    ) : (
      <ReceptionDoctorMobile
        days = {days}
        data = {filtered}
        active = {active}
        prevWeek = {prevWeek}
        nextWeek = {nextWeek}
        prevMounth = {prevMounth}
        nextMounth = {nextMounth}
        DoctorModal = {DoctorModal}
        viewPhoto = {viewPhoto}
        messageActive={messageActive}
        closeMessageSwipe={closeMessageSwipe}
      />
    )}
    </>
  )

}
export default ReceptionDoctor;