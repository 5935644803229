import React, {useState} from 'react';
import Buttons from '../buttons/buttons';
import Session from '../../helpers/Session';
import './menu.css';

function Menu( ) {

let session = new Session;
const [active,setActive] = useState(false);
  
function Hover() {
    setActive(true);
}

function NotHover() {
    setActive(false);
    
}

function exit() {
    session.removeSession('token');
    window.location.reload();
}


  return (
    <div className={"menu"+`${active ? " active" : ""}`} onMouseLeave={NotHover} onMouseOver={Hover}>
        <div className={"menu__button"}><span className="icon icon_xl icon_profile-block icon_background-contain" style={{zIndex: 1, position: "relative"}}></span></div>
        {active && 
            <div className={"menu__list"} style={{textAlign:"center"}}>
                <Buttons onClick={exit} type="link" text="Выйти из кабинета"/>
            </div> 
        }
    </div>
  )

}

export default Menu;
