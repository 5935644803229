import React from 'react';
import './styles/profile.css';


function DiscountBlock({discount}) {

    return (
        <div className="discount-block">
            <div className="discount-block__container">
                <div className="discount-block__header">
                {/* <div className="discount-block__icon">
                    <span className="icon icon_big icon_background-auto icon_discount"></span>
                </div> */}
                <div className="discount-block__text">
                    ВАША ДЕЙСТВУЮЩАЯ СКИДКА* СОСТАВЛЯЕТ:
                </div>
                <div className="discount-block__discount">
                    {(discount) + "%"}
                </div>
                </div>
                <div className="discount-block__footer">
                    * Данная скидка может действовать не на все услуги!
                </div>
            </div>
        </div>
    )

}

export default DiscountBlock;
