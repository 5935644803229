import React, { useState, useEffect } from 'react';
import WebApi from '../../helpers/WebApi';
import '../reception/reception-block.css'
import { useSnackbar } from '../../notification/index';
import ReseptionServicesBlock from './reception-services-block.jsx';
import Search from '../search/Search';


function ReceptionServices({ onClick, data, onClickType, activeType, idType, typeService }) {

  let services;

  const [filtered, setFiltered] = useState(false);

  let filter_data = data.filter((s) => {
    let children_count = data.reduce((count, x) => (x.parentId == s.id ? count + 1 : count), 0);
    if (children_count == 0) return true;
  });

  function searchResult(filter_array) { setFiltered(filter_array); }

  if (filtered || Array.isArray(filtered)) {
    services = filtered;
  } else {
    services = data.filter((el) => { return !el.parentId; });
  }

  return (
    <div className={"reception-services"}>
      <div className="reception-services__container">
        {typeService.length > 1 &&
          <div className="reception-services__type-service">
            <div className="reception-services__type-info">
              <div className="reception-services__type-title">
                <div className="reception-services__type-icon">
                  <span className="icon icon_m icon_background-auto icon_type-reception"></span>
                </div>
                <div className="reception-services__type-text">
                  <div>Выберите тип услуги</div>
                  <div>На основании которого будет проходить
                    медицинское обследование</div>
                </div>
              </div>
            </div>
            <div className="reception-services__type-content">
              {typeService !== 'error' &&
                <>{typeService.map((type, keyId) =>
                  <div id={type.key} data-id={keyId} key={keyId + 10} className={"reception-services__type-block" + `${idType == type.key && activeType == true && data ? " active" : ""}`} onClick={onClickType}>{type.value}</div>
                )}</>
              }
            </div>
          </div>
        }
        {typeService &&
          typeService.map(el =>
            <div key={el.key} className={"reception-services__message" + `${idType == el.key && el.comment != '' ? " active" : ""}`} style={{ marginBottom: '24px', marginTop: '-24px', textAlign: 'center' }}>Примечание к услуге: {el.comment}</div>
          )
        }
        <div className="reception-services__content">
          <div style={{ marginBottom: '24px' }}><Search data={filter_data} field="name" callback={searchResult} /></div>
          {data !== 'error' && services.length > 0 ? (
            <>{services.map((el, i) => <React.Fragment key={i + 10}>
              <ReseptionServicesBlock i={i + 1} data={data} id={el.id} name={el.name} price={el.price} parentId={el.parentId} onClick={onClick} />
            </React.Fragment>
            )}</>) : (<>
              {filtered && filtered.length == 0 ? (
                <div>По данному запросу услуги не найдены</div>
              ) : (
                <div>Услуги по данному типу отсутствуют</div>
              )}
            </>)}
        </div>
      </div>
    </div>
  )

}

export default ReceptionServices;