import React , { useState, useEffect} from 'react';
import { NavLink} from 'react-router-dom';
import { useLocation } from 'react-router';
import './styles/navigation.css';
import axios from 'axios';

function Navigation() {

    let width = window.screen.width;
    let classIcon = 'icon_m';

    const [widthScreen, setWithScreen] = useState(width);

    window.onresize = function() {
        setWithScreen(width);
    };

    if (widthScreen <= 768) {
        classIcon = 'icon_s';
        
        if (widthScreen <= 440) {
            classIcon = "icon_xs"
        } else {
            classIcon = 'icon_s'
        }
    } 

    // Перенаправление на 1 этап записи на прием, при нажатии на кнопку "Записаться на приём"

    let location = useLocation();

    function ReceptionReload() {
        if (location.pathname == './reception') {
        window.location.reload();
        }
    }

    const [config, setConfig] = useState([]);
    

    return (
        <nav className="navigation">
            <div className="navigation__item">
                <NavLink to="/services">
                    <div className="navigation__icon">
                        <span className={"icon icon_services " + (classIcon) + " icon_background-contain"}></span>
                    </div>
                    <div className="navigation__link">
                        Услуги
                    </div>
                </NavLink>
            </div>
            <div className="navigation__item">
                <NavLink to="/all_documents">
                    <div className="navigation__icon">
                        <span className={"icon icon_documents " + (classIcon) + " icon_background-contain"}></span>
                    </div>
                    <div className="navigation__link">
                        Документы
                    </div>
                </NavLink>
            </div>
            <div className="navigation__item">
                <NavLink to="/my_notes">
                    <div className="navigation__icon" >
                        <span className={"icon icon_notes " + (classIcon) + " icon_background-contain"}></span>
                    </div>
                    <div className="navigation__link">
                        Приемы
                    </div>
                </NavLink>
            </div>
            <div className="navigation__item">
                <NavLink to="/reception" id="reception-link" onClick={ReceptionReload}>
                    <div className="navigation__icon">
                        <span className={"icon icon_reception " + (classIcon) + " icon_background-contain"}></span>
                    </div>
                    <div className="navigation__link">
                        Запись
                    </div>
                </NavLink>
            </div>
        </nav>
    )

}

export default Navigation;




