import React from "react";
import DateRangePicker from "react-daterange-picker";
import "./react-calendar.css";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import 'moment/locale/ru';
const moment = extendMoment(originalMoment);

class FilterDate extends React.Component {
  constructor(props, context) {
    super(props, context);

    const today = moment();

    this.active = false;
    this.ttt = false;

    this.state = {
      isOpen: false,
      value: false,
      data_active: this.props.active,
    };
    
  };

  

  onSelect = (value, states) => {
    this.setState({ value, states });
    this.setState({ data_active: !this.state.active });
    this.onToggle();
    let start = moment(value.start, moment.ISO_8601);
    let end = moment(value.end, moment.ISO_8601).add({hours:23,minutes:59,seconds:59,milliseconds:999});
    //this.setState({gg:true });
    this.props.onChange(start,end);
  };

  onToggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  componentDidMount() {

    if (this.props.start_date && this.props.end_date) {
      if (this.state.value) {
        if (this.props.start_date != this.state.value.start.valueOf() && this.props.end_date != this.state.value.end.valueOf()) {
          this.setState({value: moment.range(this.props.start_date, this.props.end_date)});
        }
      } else {
        this.setState({value: moment.range(this.props.start_date, this.props.end_date)});
      }
    }

    if (this.active != this.active) { this.setState({data_active : this.props.active}); this.active = this.props.active; }
    document.addEventListener('click', this.outSideClick, true);
    return () => {
    document.removeEventListener('click', this.outSideClick, true);
    };

  }

  componentDidUpdate() {
    
    if (this.props.start_date && this.props.end_date) {
      if (this.state.value) {
        if (this.props.start_date != this.state.value.start.valueOf() && this.props.end_date != this.state.value.end.valueOf()) {
          this.setState({value: moment.range(this.props.start_date, this.props.end_date)});
        }
      } else {
        this.setState({value: moment.range(this.props.start_date, this.props.end_date)});
      }
    }

    if (this.props.active != this.active) { this.setState({data_active : this.props.active}); this.active = this.props.active; }
    document.addEventListener('click', this.outSideClick, true);
    return () => {
    document.removeEventListener('click', this.outSideClick, true);
    };

  }

  outSideClick = (event) => {
     let allclasses = event.srcElement.classList;
     var partofclassname='DateRangePicker__';
     let wanted_class = Object.values(allclasses).find(c => c.indexOf(partofclassname) !== -1);
     if (!wanted_class) {
      this.setState({ isOpen: false });
     }

  };

  CloseSelect = () => {
    this.setState({value: false});
    this.setState({ data_active: 0 });
    this.props.onClose();
  }

  renderSelectionValue = () => {
    return (
      <div className={"filter-data__content"+`${this.state.data_active != 0 ? " active" : ""}`}>
        <div className={"filter-data__triger"} onClick={this.onToggle}> 
          <div className={"filter-data__title"+`${this.state.data_active != 0 ? " bold" : ""}`}>{this.props.title}</div>
          {this.state.value && this.state.data_active ? (
          <div >{this.state.value.start.format("DD.MM.YYYY")} - {this.state.value.end.format("DD.MM.YYYY")}
          </div>
          ) : (
            <div >Нажмите для фильтрации</div>
          )}
        </div>
        <div className={"filter-data__button-close" + `${this.state.data_active != false ? " active" : ""}`}  onClick={this.CloseSelect}>
          <span className="icon icon_xs icon_background-auto icon_close-filter"></span>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="filter-data">
        <div className="filter-data__container">{this.renderSelectionValue()}</div>
          
        {this.state.isOpen && (
          <DateRangePicker
            value={this.state.value}
            onSelect={this.onSelect}
            singleDateRange={true}
            firstOfWeek={1}
            locale={moment.locale('ru')}
          />
        )}
      </div>
    );
  }
}

export default FilterDate;
