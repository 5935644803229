import React, { useEffect } from "react";
import Buttons from "../buttons/buttons";
import './cards.css';

function Cards({ type, title, subtitle, info, days, price, status, line, children, onClickLeft, onClickRight, ModalWindow, isCompleted, isCanceled }) {

    function declOfNum(n, text_forms) {  
        n = Math.abs(n) % 100; var n1 = n % 10;
        if (n > 10 && n < 20) { return text_forms[2]; }
        if (n1 > 1 && n1 < 5) { return text_forms[1]; }
        if (n1 == 1) { return text_forms[0]; }
        return text_forms[2];
    }
    
    // мои записи
    let label_class = '';
    let label_text = '';

    // услуги
    let status_text = '';
    let status_class = '';

    let background_color = 'card_background-default';
    

    let cards_class = {
        'card-services': {
            'main':'card_services',
            'left_footer':'card_services-price',
            'left_footer_text':'card__price-text',
            'left_footer_img':'card__price-img',
            'right_footer':'card__services-status',
            'right_footer_text':'card__services-text',
            'right_footer_img':'card__services-img',
        },
        'card-notes': {
            'main':'card_notes',
            'left_footer':'card__notes-time',
            'left_footer_text':'card__notes-text',
            'left_footer_img':'card__notes-img',
            'right_footer':'card__notes-status',
            'right_footer_text':'card__notes-text',
            'right_footer_img':'card__notes-img',
            'icon':'icon icon_xs icon_background-auto icon_close-reception',
            'icon_label':'icon icon_xs icon_background-auto icon_recepiton-time',
            'icon_complete':'icon icon_xs icon_background-auto icon_recepiton-complete',
            'icon_cancel':'icon icon_xs icon_background-auto icon_close-reception',
            
        },
        'card-documents': {
            'main':'card_documents',
            'right_footer':'card__documents-dolwnd',
            'right_footer_text':'card__documents-text',
            'right_footer_img':'card__documents-img',
            'icon':'icon icon_xs icon_background-auto icon_document-dolwnd'
        },
    }

    let cards_array = cards_class[type];

    const [ContentActive, setContentAvtive] = React.useState(false);

    //Управление дополнительной информацией для карточек 

    let window_width = window.outerWidth;
    
    window.addEventListener('resize', function(event) {
        window_width = window.outerWidth;
        if (hover_cards.length && window_width > 768) {
            hover_cards.forEach((card) => {
                card.style.marginBottom = 0;
                card.style.zIndex = '';
                card.classList.remove('active');
            })
            hover_cards = [];
        }
    }, true);

    let hover_cards = [];

    useEffect(() => {
        let detect = document.querySelector('.detect');
        if (detect) hover_cards = [detect];
    })

    function notActionButton(e) {
        
        if (hover_cards.length) {
            hover_cards.forEach((card) => {
                card.style.marginBottom = 0;
                card.style.zIndex = '';
                card.classList.remove('active');
                card.classList.remove('detect');
                card.querySelector('.card__title').style.display = '-webkit-box';
            })
        }

    }

    function actionButton(e) {
        let height = 0;
        let all_card = document.querySelectorAll('.modal-window .card').length;
        let card = e.target.closest('.card');
        let card_info = card.querySelector('.card__additional-info');
        let card_title = card.querySelector('.card__title');

        if (window_width > 768) {
            card_info.style.display = 'block';
            card_title.style['-webkit-line-clamp'] = 'unset';
            height = card_info.offsetHeight + card_title.offsetHeight;
            card_info.style.display = '';
            card_title.style['-webkit-line-clamp'] = '';
            if (all_card <= 1) height = height - 32;
            card.style.marginBottom = '-' + height + 'px';
            card.style.zIndex = '99';
        }
        console.log(height);
        card.classList.add('active');
        card.classList.add('detect');
        hover_cards.push(card); 

        card.querySelector('.card__title').style.display = '-webkit-box';

    }

    const ButtonHover = (e) => { if (window_width > 768) {actionButton(e);} };
    const ButtonNotHover = (e) => { if (window_width > 768) {notActionButton(e);} }; 
    const ButtonClick = (e) => { if (window_width <= 768) { 
        e.preventDefault();
        let prev_card = document.querySelector('.detect');
        if (prev_card) {
            prev_card.style.marginBottom = 0;
            prev_card.style.zIndex = '';
            prev_card.classList.remove('active');
            prev_card.classList.remove('detect');
            prev_card.querySelector('.card__title').style.display = '-webkit-box';
        }
        actionButton(e);
        e.preventDefault();
    } }; 

    if (days !== undefined) {

            if (isCompleted == true) {
                label_class = label_class + 'card_notes-complete';
                label_text = 'Завершен'; 
            }

            if (isCanceled == true) {
                label_class = label_class + 'card_notes-cancel';
                label_text = 'Отменен';
            }
      
            if (days >= 0 && days < 1) {
                label_class = label_class + ' card_notes-danger';
                label_text = 'Сегодня'; //Сегодня
                background_color = 'card_background-danger'
            }

            if (days >= 1 && days < 2) {
                label_class = label_class + ' card_notes-warning';
                label_text = 'Завтра'; //Вывести завтра
                background_color = 'card_background-warning'
            }

            if (days >= 2) {
                label_class = label_class + ' card_notes-success';
                label_text = 'Через ' + Math.floor(days) + ' ' +declOfNum(days, ['день', 'дня', 'дней']); //Вывести сколько дней осталось
                background_color = 'card_background-success'
            }
    }


    if (status != undefined) {
        
        if (status == 0) {
            status_class = status_class + 'card_services-danger';
            status_text = 'не оплачено';
            background_color = 'card_background-danger'
        }

        if (status == 2) {
            status_class = status_class + 'card_services-warning';
            status_text = 'частично';
            background_color = 'card_background-warning'
        }

        if (status == 1) {
            status_class = status_class + 'card_services-success';
            status_text = 'оплачено';
            background_color = 'card_background-default'
        }

    }

    if( type == 'card-documents' && ContentActive == true) {
        background_color = 'card_background-primary';
    }

    if( type == 'card-services' && status == 1 && ContentActive == true) {
        background_color = 'card_background-success';
    }

    if( type == 'card-services' && status == 2 && ContentActive == true) {
        background_color = 'card_background-warning';
    }

    return (
        <div className={'card' + ' ' + cards_array.main + `${ContentActive ? " active" : ""}` + `${isCanceled == true ? " cancel" : ""}`} onMouseLeave={ButtonNotHover}>
            <div className={"card__background " + (background_color)}></div>
            <div className="card__container">
                <div className="card__header">
                    <div className="card__subtitle">
                        {subtitle}
                    </div>
                    <div className="card__info-icon" onMouseOver={ButtonHover}>
                        <span style={{cursor:'pointer'}} className="icon icon_s icon_rigth icon_background-auto icon_card-info" onClick={ButtonClick}></span>
                    </div>
                </div>
                <div className="card__content">
                    <div className="card__title" title={title}>{title}</div>
                    <div className="card__info">{info}</div>
                    <div className={'card__additional-info'}>
                        {children}
                    </div>
                </div>
                <div className="card__additional-content">
                    <div className="card__line">
                        <div className="card__border-line"></div>
                    </div>
                    <div onClick={ModalWindow}>Посмотреть услуги</div>
                </div>
                <div className="card__line">
                    <div className="card__border-line"></div>
                    <div className="card__line-text">{line &&
                        <span>{line}</span> 
                    }</div>
                </div>
                <div className="card__footer">
                    <div className="card__footer-left">
                        <div className={cards_array.left_footer + " " + (label_class)}>
                            {price && 
                                <div>
                                    {price}
                                </div>
                            }
                            {days >= 0 &&
                                <div className={cards_array.left_footer_img}>
                                    <span className={cards_array.icon_label}></span>
                                </div>       
                            }
                            {isCanceled == true &&
                                <div className={cards_array.left_footer_img}>
                                    <span className={cards_array.icon_cancel}></span>
                                </div>
                            }
                            {isCompleted == true &&
                                <div className={cards_array.left_footer_img}>
                                    <span className={cards_array.icon_complete}></span>
                                </div>
                            }
                            <div className={cards_array.left_footer_text}>
                                {label_text}
                            </div>
                            {type == 'card-documents' &&
                                (<Buttons disabled={!onClickLeft && true} onClick={onClickLeft} type="button-square" icon="icon icon_xs icon_button-viewing icon_background-auto"/>)
                            }
                        </div>
                        
                    </div>
                    <div className="card__footer-right">
                        <div className={cards_array.right_footer + " " + (status_class)}>
                            { days < 0 ? (
                                <div className={cards_array.right_footer_img}>
                                    <span></span>
                                </div>)
                            : (
                                <div className={cards_array.right_footer_img}>
                                    <span className={cards_array.icon}></span>
                                </div>
                            )
                            }
                            
                            <div className={cards_array.right_footer_text}>
                                {status != undefined && 
                                    <div>
                                        {status_text}
                                    </div>
                                }
                                {type=='card-notes' && days >= 0 &&
                                    <div onClick={onClickRight}>
                                        Отменить
                                    </div>
                                }
                                {type=='card-documents' && 
                                    <div onClick={onClickRight}>
                                        Скачать
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Cards;