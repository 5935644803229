import React, { useEffect, useState } from 'react';

function ReceptionBlock({children, data, onClick, activeDone, branchesCount, currentTab, NextClick, PrevClick, severRes}) {
    
    let i = 1;
    let m_i = 1;

    let width = window.screen.width;
    let IconTabs = 'icon_l';

    const [widthScreen, setWithScreen] = useState(width);

    window.onresize = function() {
        setWithScreen(window.screen.width);
    };

    if (widthScreen <= 1400) {
        IconTabs = 'icon_s';
    } else {
        IconTabs = 'icon_l';
    }

    // Проверка шагов для мобильных
    let prevTabActive = true;
    let nextTabActive = true;

    if (currentTab == 'branch')
    if (data.clinicBranchId) nextTabActive = true; else nextTabActive = false;

    if (currentTab == 'service')
    if (severRes !== false) {nextTabActive = true;} else {nextTabActive = false}

    if (currentTab == 'doctor')
    if (data.doctorSpecializationId) nextTabActive = true; else nextTabActive = false;

    if (currentTab == 'success') nextTabActive = false;

    if (currentTab == 'finish') { prevTabActive = false; nextTabActive = false; }

    return (
        <div className="reception__container">
            <div className="reception__tab">
                {branchesCount != 1 &&
                <div className="reception__tab-block active" id="branch" onClick={!activeDone ? onClick : () => {}}>
                    <div className="reception__tab-icon">
                        <span className={"icon "+ (IconTabs) + " icon_background-auto icon_left icon_step-1"}></span>
                    </div>
                    <div className="reception__tab-content">
                        <div className="reception__tab-title">
                            Шаг {i++}
                        </div>
                        <div className="reception__tab-subtitle">
                            Выберите филиал
                        </div>
                    </div>
                </div>
                }
                <div className={"reception__tab-block" + `${data.clinicBranchId ? " active" : ""}`} id="services" onClick={data.clinicBranchId && !activeDone ? onClick : () => {}} >
                    <div className="reception__tab-icon">
                        <span className={"icon "+ (IconTabs) + " icon_background-auto icon_left icon_step-2"}></span>
                    </div>
                    <div className="reception__tab-content">
                        <div className="reception__tab-title">
                            Шаг {i++}
                        </div>
                        <div className="reception__tab-subtitle">
                            Выберите услугу
                        </div>
                    </div>
                </div>
                <div className={"reception__tab-block" + `${data.serviceId ? " active" : ""}`} id="doctor" onClick={data.serviceId && !activeDone ? onClick : () => {}}>
                    <div className="reception__tab-icon">
                        <span className={"icon "+ (IconTabs) + " icon_background-auto icon_left icon_step-3"}></span>
                    </div>
                    <div className="reception__tab-content">
                        <div className="reception__tab-title">
                            Шаг {i++}
                        </div>
                        <div className="reception__tab-subtitle">
                            Выберите врача
                        </div>
                    </div>
                </div>
                <div className={"reception__tab-block" + `${data.doctorSpecializationId ? " active" : ""}`} id="success" onClick={data.doctorSpecializationId && !activeDone ? onClick : () => {}}>
                    <div className="reception__tab-icon">
                        <span className={"icon "+ (IconTabs) + " icon_background-auto icon_left icon_step-4"}></span>
                    </div>
                    <div className="reception__tab-content">
                        <div className="reception__tab-title">
                            Шаг {i++}
                        </div>
                        <div className="reception__tab-subtitle">
                            Проверьте данные
                        </div>
                    </div>
                </div>
                <div className={"reception__tab-block" + `${activeDone ? " active" : ""}`}>
                    <div className="reception__tab-icon">
                        <span className={"icon "+ (IconTabs) + " icon_background-auto icon_left icon_step-5"}></span>
                    </div>
                    <div className="reception__tab-content">
                        <div className="reception__tab-title">
                            Готово
                        </div>
                        <div className="reception__tab-subtitle"></div>
                    </div>
                </div>
            </div>
            <div className="reception__mobile">
                <div className="reception__mobile-container">
                    <div className={"reception__mobile-prev" + `${currentTab == 'branch' ? " hidden" : ""}` + `${currentTab == 'service' && branchesCount == 1 ? " hidden" : ""}` + `${prevTabActive ? "" : " disabled"}`} onClick={prevTabActive ? PrevClick : () => {}}>
                        <span className="icon icon_m icon_background-auto icon_reception-prev"></span>
                    </div>
                    <div className="reception__mobile-content">
                        <div className="reception__mobile-title">Запись на приём</div>
                        <div className="reception__mobile-step">
                            {branchesCount != 1 && (<>
                                <span style={currentTab == "branch" ? {display:"block"} : {display:"none"} }>Шаг {m_i++} - Выберите филиал</span>
                            </>)}
                            <span style={currentTab == "service" ?  {display:"block"} : {display:"none"} }>Шаг {m_i++} - Выберите услугу</span>
                            <span style={currentTab == "doctor" ? {display:"block"} : {display:"none"} }>Шаг {m_i++} - Выберите врача</span>
                            <span style={currentTab == "success" ?  {display:"block"} : {display:"none"} }> Шаг {m_i++} - Проверьте данные</span>
                            <span style={currentTab == "finish" ? {display:"block"} : {display:"none"} }>Готово</span>
                        </div>
                    </div>
                    <div className={"reception__mobile-next" + `${currentTab == 'finish' ? " hidden" : ""}` + `${nextTabActive ? "" : " disabled"}`} onClick={nextTabActive ? NextClick : () => {}}>
                        <span className="icon icon_m icon_background-auto icon_reception-next"></span>
                    </div>
                </div>
            </div>
            <div className="reception__content">
                {children}
            </div>
        </div>
    )
    
    
    
    
    
}

export default ReceptionBlock;
