import React, { useEffect, useState, useLayoutEffect } from 'react';
import Swipe from 'react-easy-swipe';
import Buttons from '../buttons/buttons';
import MiniModal from '../mini modal/miniModal';

function ReceptionDoctorMobile({days, data, active, prevWeek, nextWeek, prevMounth, nextMounth, DoctorModal, viewPhoto, messageActive, closeMessageSwipe }) {
  
    const [swipeLength,setSwipeLenght] = useState({
        lenght: 0,
        days: 0
    });
    
    const [translate,setTranslate] = useState({
        days: 0,
        doctor: 0,
        change_day: 1
    });

    const [styleDays,setStyleDays] = useState({
        days: {transform: "translateX("+translate.days+"px)"},
        doctor: {transform: "translateX("+translate.doctor+"px)"}
    });
    
    let step_translate = {
        days: window.innerWidth/2 - 32,
        doctor: window.innerWidth/2 - 48,
    };

    useLayoutEffect(() => {

        function updateSize() {
            step_translate = {
                days: window.innerWidth/2 - 32,
                doctor: window.innerWidth/2 - 48,
            };
            setTranslate(prevState => ({...prevState,
                days: translate.days,
                doctor: translate.doctor,
                change_day: translate.change_day
            }));
        }
        window.addEventListener('resize', updateSize);

        updateSize();

        return () => window.removeEventListener('resize', updateSize);

    }, []);
  
  function onSwipeLeft(click = false) {

    if(swipeLength.lenght < -100 || click) {

      if (translate.change_day < 7) {
        setTranslate(prevState => ({...prevState,
          days: translate.days - step_translate.days,
          doctor: translate.doctor - step_translate.doctor,
          change_day: translate.change_day + 1
        }));
      } else {
        setTranslate(prevState => ({...prevState,
          days: translate.days,
          doctor: translate.doctor,
          change_day: 7
        }));
      }

    } else {
        setTranslate(prevState => ({...prevState,
            days: translate.days,
            doctor: translate.doctor
        }));
    }
  }

  function onSwipeMove(position, event) {

    if (position.x > 30) {

      setSwipeLenght({
        lenght: position.x,
        days: translate.days + position.x - 30,
        doctor: translate.doctor + position.x - 30,
      });

    }
    
    if (position.x < -30) {

      setSwipeLenght({
        lenght: position.x,
        days: translate.days + position.x + 30,
        doctor: translate.doctor + position.x + 30,
      });

    }

  }

  function onSwipeRight(click = false) {
    if(swipeLength.lenght > 100 || click) {

      if (translate.change_day > 1) {
        setTranslate(prevState => ({...prevState,
          days: translate.days + step_translate.days,
          doctor: translate.doctor + step_translate.doctor,
          change_day: translate.change_day - 1
        }));
      } else {
        setTranslate(prevState => ({...prevState,
          days: translate.days,
          doctor: translate.doctor,
          change_day: 1
        }));
      }

    } else {
        setTranslate(prevState => ({...prevState,
            days: translate.days,
            doctor: translate.doctor
        }));
    }
  }

  function clickDays(event) {
    
  }

  useEffect(() => {

    setStyleDays(prevState => ({...prevState,
        days: {transform: "translateX("+translate.days+"px)"},
        doctor: {transform: "translateX("+translate.doctor+"px)"}
    }));

  },[translate])

  useEffect(() => {

    setStyleDays(prevState => ({...prevState,
        days: {transform: "translateX("+swipeLength.days+"px)"},
        doctor: {transform: "translateX("+swipeLength.doctor+"px)"}
    }));

  },[swipeLength])

  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [isModal, setModal] = useState(false);

  function viewDoctorInfo(index){   
    let specializations = data[index].specializations.map((e, i) => {
      return ' ' + e.name + (data[index].specializations.length > 1 && i != Number(data[index].specializations.length - 1) ? ',' : '');
    });
    setSelectedDoctor({...data[index], specializations, fullName: data[index].fullName || data[index].userName});
    setModal(true);
  }

    return (
        <div className={"reception-doctor-mobile"}>
          <MiniModal
            isVisible={isModal}
            title={selectedDoctor?.fullName}
            content={<p><b>{selectedDoctor?.specializations}</b> <br/>{selectedDoctor?.description} </p>}
            imageSrc={selectedDoctor?.photoUrl}
            onClose={(e) => {e.stopPropagation();setModal(false)}}
          />
          {messageActive == true && 
          <div className={"reception-doctor-mobile__message"  + `${messageActive == true ? " active" : ""}`}>
            <div className="reception-doctor-mobile__close" onClick={closeMessageSwipe}>
              <span className="icon icon_m icon_message-swipe-close icon_background-auto"></span>
            </div>
            <div className="reception-doctor-mobile__icon">
              <span className="icon icon_big icon_messageSwipe icon_background-auto"></span>
            </div>
            <div className="reception-doctor-mobile__text">
              На данной странице используются жесты. Листайте вправо и влево для смены дней.
            </div>
          </div>
          }
        <div className="reception-doctor-mobile__header">
            <div className="reception-doctor__week">
            <div className="reception-doctor__prevweek" onClick={prevWeek} >
                <div><span className="icon icon_xs icon_background-auto icon_prev"></span></div>
                <div>Предыдущая неделя</div>
            </div>
            <div className="reception-doctor__nextweek" onClick={nextWeek}>
                <div>Следующая неделя</div>
                <div><span className="icon icon_xs icon_background-auto icon_next"></span></div>
            </div>
            </div>
            <div className="reception-doctor__mounth">
            <div className="reception-doctor__prevmounth" onClick={prevMounth}>
                <div><span className="icon icon_xs icon_background-auto icon_prev"></span></div>
                <div>Предыдущий месяц</div>
            </div>
            <div className="reception-doctor__nextmounth" onClick={nextMounth}>
                <div>Следующий месяц</div>
                <div><span className="icon icon_xs icon_background-auto icon_next"></span></div>
            </div>
            </div>
        </div>
        <div className="reception-doctor-mobile__days">
            <div className="reception-doctor-mobile__click-left" onClick={onSwipeRight}></div>
            <Swipe onSwipeLeft={onSwipeLeft} onSwipeMove={onSwipeMove} onSwipeRight={onSwipeRight} onClick={event => clickDays(event)}>
                {days.map((day,keyId) =>
                <div key={keyId} className={"reception-doctor-mobile__days-block " + `${translate.change_day -1 == keyId ? " active" : ""}`} style={styleDays.days} >
                    <div>{day.name}</div>
                    <div>{day.date}</div>
                </div>
                )}
            </Swipe>
            <div className="reception-doctor-mobile__click-right" onClick={onSwipeLeft}></div>
        </div>
        <div className="reception-doctor-mobile__content">
            {data.length ? <>{data.map((doctor,i) => 
              <Swipe onSwipeLeft={onSwipeLeft} onSwipeMove={onSwipeMove} onSwipeRight={onSwipeRight} key={i}>
                <div className="reception-doctor-mobile__block">
                  <div className="reception-doctor-mobile__doctor">
                  <div className="reception-doctor-mobile__photo-doctor">
                      {doctor.photoUrl != null ?
                          (<img src={doctor.photoUrl} onClick={(e) => {e.stopPropagation(); viewDoctorInfo(i)}} />) :
                          (<span className="icon icon_l icon_background-auto icon_profile-block" onClick={(e) => {e.stopPropagation(); viewDoctorInfo(i)}}></span>)
                      }
                  </div>
                  <div>
                    {doctor.userName}
                    <div className='doctor__about' onClick={(e) => {e.stopPropagation(); viewDoctorInfo(i)}}><u>О враче</u></div>
                  </div>
                  </div>
                  {days.map((day,index) => {
                  return (<React.Fragment key={index}>
                      {doctor.arrFreeTimes[day.date_utc] != undefined ? (
                      <div className="reception-doctor-mobile__time active" onClick={() => DoctorModal(doctor,day)} style={styleDays.doctor}>
                        <span>c {doctor.arrFreeTimes[day.date_utc][0].startWorkTime.slice(-8,-3)} до {doctor.arrFreeTimes[day.date_utc][0].endWorkTime.slice(-8,-3)}</span>
                        <span>выбрать время</span>
                      </div>
                      ) : (<>
                      <div className="reception-doctor-mobile__time" style={styleDays.doctor}>----</div>
                      </>)}

                      </React.Fragment>);
                  })}
              </div>
              </Swipe>
            )}</> : 
            <div style={{textAlign:'center',paddingTop:'24px'}}>
                Нет записей на данный период
            </div>
            }
        </div>
        </div>
    )
    
}

export default ReceptionDoctorMobile;
