import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import './App.css'
import './reset.css'
import './App.css'
import Context from './context.js'
import Login from './sections/Login.jsx';
import Services from './sections/Services.jsx';
import Session from './helpers/Session.js';
import AllDocuments from './sections/AllDocuments.jsx';
import MyNotes from './sections/MyNotes.jsx';
import Reception from './sections/Reception.jsx';
import SideBar from './components/SideBar.jsx';
import ResetPassword from './sections/ResetPassword.jsx';
import Profile from './components/Profile';
import axios from 'axios';

function App() {

  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') window.config = require('./config.json');

  let config = window.config;
  let session = new Session;
  let token = session.getSession('token');

  const [global, setGlobal] = useState({
    profile: {
      toggle: false,
      data: false
    }
  });

  if (!token) {

    return (<>
      <style>{`
        :root {
          --default: `+ config.color_default + `;
          --neutral: #ebebeb;
          --text: `+ config.color_default_text + `;
          --text_inverse: `+ config.color_primary_text + `;
          --text_color: red;
          --primary: `+ config.color_primary + `;
          --success: #29d27e;
          --warning: #f9be1b;
          --danger: #ff6d6d;
          --gray: #707070;
          --icons_primary_inverse: `+ config.icons_primary_inverse + `;
          --icons_default_inverse: `+ config.icons_default_inverse + `;
        }
      `}</style>
      <BrowserRouter basename={(config.batch) ? config.batch : '/'}>
        <Context.Provider value={[global, setGlobal]}>
          <Redirect to="/" />
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <Route component={Reception} />
          </Switch>
        </Context.Provider>
      </BrowserRouter>
    </>)
  } else {
    return (<>
      <style>{`
        :root {
          --default: `+ config.color_default + `;
          --neutral: #ebebeb;
          --text: `+ config.color_default_text + `;
          --text_inverse: `+ config.color_primary_text + `;
          --text_color: red;
          --primary: `+ config.color_primary + `;
          --success: #29d27e;
          --warning: #f9be1b;
          --danger: #ff6d6d;
          --gray: #707070;
          --icons_primary_inverse: `+ config.icons_primary_inverse + `;
          --icons_default_inverse: `+ config.icons_default_inverse + `;
        }
      `}</style>
      <BrowserRouter basename={(config.batch) ? config.batch : '/'}>
        <Context.Provider value={[global, setGlobal]}>
          <div className="main">
            <div>
              <SideBar />
            </div>
            <div className="content">
              <Switch>
                <Route exact path="/" render={({ location }) => <Redirect to={{ pathname: "/services", state: location.state }} />} />
                <Route exact path="/services" component={Services} />
                <Route exact path="/all_documents" component={AllDocuments} />
                <Route exact path="/my_notes" component={MyNotes} />
                <Route exact path="/reception" component={Reception} />
                <Route component={Reception} />
              </Switch>
            </div>
          </div>
          <Profile />
        </Context.Provider>
      </BrowserRouter>
    </>)
  }


}

export default App;
