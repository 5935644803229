import React, { useEffect } from "react";

import './forms.css';
import '../icons/icons.css';

function Buttons({ placeholder, className, iconImage, iconRightImage, iconRightImageSize, onRightIconClick, iconSize, iconPosition, modify,Func, type, name, error, errorMessage, value, inputRef, onChange, tooltip}) {

    return (
    <div  title={tooltip}>
        {error == true &&
            <div className="error-message">{errorMessage}</div>
        }
        <div className={className + `${error == true ? " error" : ""}`}>
            <div className={className + "__icon"}>
                <span className={"icon " + iconImage + " " + iconSize + " " + iconPosition}></span>
            </div>
            <input type={type}  name={name} ref={inputRef} placeholder={placeholder} value={value} onClick={Func} onChange={onChange}/>
            {iconRightImage &&            
                <div className={className + "__icon__right"} onClick={onRightIconClick}>
                    <span className={"icon " + iconRightImage + " " + iconRightImageSize + " " + iconPosition}></span>
                </div>
            }
        </div>
    </div>
    );
}

export default Buttons;