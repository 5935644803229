import React from 'react';
import './styles/sidebar.css';

import Navigation from './navigation';


function SideBar() {

    let config = window.config;

    return (
        <div className="sidebar">
            <div className="sidebar__container">
                <div className="sidebar__header">
                    <img src={config.logo_inverse} alt="" />
                </div>
                <div className="sidebar__content">
                    <Navigation />
                </div>
                <div className="sidebar__footer">
                    <p>Все права защищены "{config.name_company}"</p>
                </div>
            </div>
        </div>
    )

}

export default SideBar;
