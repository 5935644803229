import React, { useContext } from 'react';
import { useLocation } from 'react-router';
import Buttons from './buttons/buttons';
import './styles/header.css'
import Profile from '../components/Profile.jsx'
import Context from '../context.js'
import Menu from './menu/menu';


function HeaderMobile({ title, value, type, onClick, active }) {

  let config = window.config;
  const [global, setGlobal] = useContext(Context);

  function profile() {
    setGlobal(prevState => ({
      ...prevState,
      profile: {
        data: prevState.profile.data,
        toggle: true
      }
    }));
  }

  // Перенаправление на 1 этап записи на прием, при нажатии на кнопку "Записаться на приём"

  let location = useLocation();

  function ReceptionReload() {
    if (location.pathname == '/reception') {
      window.location.reload();
    }
  }

  return (

    <div className="header-mobile">
      <Profile />
      <div className="header-mobile__container">
        <div className="header-mobile__profile-block">
          <div className="header-mobile__logo">
            <img src={config.logo_mobile} />
          </div>
          <div className="header-mobile__profile-info">
            <div className="header-mobile__profile-text">
              <div className="header-mobile__profile-name" onClick={profile}>
                {global.profile.data && <>
                  {global.profile.data.lastName && <>{global.profile.data.lastName} </>}
                  {global.profile.data.firstName && <>{global.profile.data.firstName[0]}. </>}
                  {global.profile.data.midName && <>{global.profile.data.midName[0]}. </>}
                </>}
              </div>
            </div>
            <div className="header-mobile__profile-photo">
              <Menu />
            </div>
          </div>
        </div>
        <div className="header-mobile__button">
          <Buttons to="/reception" type="button-primary" icon="icon icon_s icon_button-record icon_background-auto" text="Записаться на прием" onClick={ReceptionReload} />
        </div>
        {!type &&
          <div className="header-mobile__content">
            <div className="header-mobile__title">
              <h1>{(title) + " (" + (value) + ")"}</h1>
            </div>
            <div className={"header-mobile__filter" + `${active == true ? " active" : ""}`} onClick={onClick}>
              <span className="icon icon_s icon_background-auto icon_filter"></span>
            </div>
          </div>
        }
      </div>
    </div>
  )


}

export default HeaderMobile;